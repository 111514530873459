
import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
// @ is an alias to /src
import axios, {AxiosResponse, AxiosError} from 'axios';
import HomeCampaignlistItem from './HomeCampaignlistItem.vue';
import $ from 'jquery';

@Component({
  components: {
    HomeCampaignlistItem,
  },
})
export default class HomeCampaignlist extends Vue {
  private　name = 'homecampaignlist';
  private defaultItems: number = 4;
  private list: PostData[] = [];
  private offset: number = 0;
  private length: number|null = 4;
  private totaleItems: number = 0;
  private isOpend = false;
  get showMore() {
    if (this.totaleItems > this.defaultItems) {
      return true;
    } else {
      return false;
    }
  }
  @Emit()
  private setList(list: PostData[]) {
    this.list = list;
  }
  @Emit()
  private async getMore() {
    // this.offset = this.firstItemsLength;
    if (this.length && this.length > 0) {
      this.length = null;
      await this.getEntries();
    }
    if (this.isOpend) {
      this.isOpend = false;
    } else {
      this.isOpend = true;
    }
    this.$nextTick(() => {
      const $list: any = $(this.$refs.list);
      if (this.isOpend) {
        $list.find('.item:hidden').stop().slideDown();
      } else {
        $list.find('.item:gt(' + (this.defaultItems - 1) + ')').stop().slideUp();
      }
    });
  }
  @Emit()
  private async getEntries() {
    let api = `/api/wp-json/31corp/v1/posts?cat=campaign&offset=${this.offset}`;
    if (this.length) {
      api += `&length=${this.length}`;
    }
    await axios({
      method: 'get',
      url: api,
      headers: { Authorization: 'Bearer ' + this.$store.getters['auth/token']},
    })
    .then( (response: AxiosResponse<ApiPost>) => {
      this.totaleItems = Number(response.data.hits);
      this.setList(response.data.datas);
    })
    .catch( (e: AxiosError) => {
      console.log(e);
    });
  }
}
