import { Component, Vue, Emit } from 'vue-property-decorator';
import axios, {AxiosResponse, AxiosError} from 'axios';
import $ from 'jquery';
import HomeSliderData_ja from '@/mixins/HomeSliderData_ja';
import HomeSliderData_en from '@/mixins/HomeSliderData_en';

@Component
export default class HomeSlider extends Vue {
  private　name = 'homeslider';
  private images: any = [];
  private randomImages: any = [];

  get lang(): string {
    return this.$store.getters['auth/lang'];
  }

  @Emit()
  private activeClass(num: number): string|null {
    if (num === 0) {
      return 'active';
    } else {
      return null;
    }
  }
  @Emit()
  private slideNumberClass(num: number): string {
    const ret = ( '00' + (num + 1) ).slice( -2 );
    return `slide${ret}`;
  }
  @Emit()
  private getEntries() {
    this.lang === 'ja' ? this.images = HomeSliderData_ja : this.images = HomeSliderData_en;
    this.randomImages = this.images.sort(() => 0.5 - Math.random()).slice(0, 4);
    this.$nextTick(() => {
      this.initSlider();
    });
  }
  // private async getEntries() {
  //   const lang = (this.$route.path.indexOf('/en/') === 0) ? 'en' : 'ja';
  //   const api = `/api/wp-json/31corp/v1/slideshow?lang=${lang}`;
  //   await axios({
  //     method: 'get',
  //     url: api,
  //     headers: { Authorization: 'Bearer ' + this.$store.getters['auth/token']},
  //   })
  //   .then( (response: AxiosResponse<ApiPost>) => {
  //     this.images = response.data;
  //     this.randomImages = this.images.sort(() => 0.5 - Math.random()).slice(0, 4);
  //     this.$nextTick(() => {
  //       this.initSlider();
  //     });
  //     return response;
  //   })
  //   .catch( (e: AxiosError) => {
  //     console.log(e);
  //   });
  // }
  @Emit()
  private initSlider() {
    const $slider = $('.slider');
    if ($slider.length > 0) {
      const $slide = $('.slide', $slider);
      const $thum = $('.thum .item', $slider);
      let counter = 0;
      setInterval(() => {
        if (++counter >= $slide.length) {
          counter = 0;
        }
        $slide.eq(counter).addClass('active').siblings().removeClass('active');
        $thum.eq(counter).removeClass('inActive').addClass('active').siblings('.active').removeClass('active').addClass('inActive');
      }, 10000);
    }
  }
}
