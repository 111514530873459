import { Component, Mixins, Provide } from 'vue-property-decorator';
import ViewPage from '@/mixins/Views';
// @ is an alias to /src

Component.registerHooks([
  'beforeRouteLeave',
]);

@Component
export default class Home extends Mixins(ViewPage) {
  @Provide()
  protected loaded = false;
  private name = 'home';
}
