import { Component, Vue, Emit } from 'vue-property-decorator';
import store from '@/store';
@Component
export default class NaviSerchnaviArea extends Vue {
  private name = 'NaviSerchnaviArea';
  @Emit()
  private closeModalSearchNavi(): void {
    store.commit('searchmodal/close', 'ModalSearchnavi');
  }
}
