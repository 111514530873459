
import { Component, Vue, Emit, Watch, Prop } from 'vue-property-decorator';
import store from '@/store';
import ModalTodayLowestPriceResultHotel from './ModalTodayLowestPriceResultHotel.vue';
import $ from 'jquery';
@Component({
  components: {
    ModalTodayLowestPriceResultHotel,
  },
})
export default class ModalTodayLowestPriceResult extends Vue {
  private name = 'modaltodaylowestpriceresult';
  @Prop()
  private today?: Date;
  @Prop()
  private area?: string;
  @Prop()
  private stay?: string;
  @Prop({default: []})
  private hotels!: ReservableList[];
  get group(): string {
    return this.$store.getters['auth/group'];
  }
  get filterHotels() {
    const res = this.hotels.filter((item: ReservableList) => {
      if (
        typeof item.schedule !== "undefined"
        && item.schedule.length > 0
        && item.schedule[0].reservable
      ) {
        return item;
      }
    });
    return res;
  }
  get todayY(): number {
    return (this.today) ? this.today.getFullYear() : 0;
  }
  get todayM(): number {
    return (this.today) ? this.today.getMonth() + 1 : 0;
  }
  get todayD(): number {
    return (this.today) ? this.today.getDate() : 0;
  }
  get todayW(): string {
    if (this.today) {
      const resD = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());
      return this.wordsDay[resD.getDay()];
    } else {
      return '';
    }
  }
  get lang(): string {
    return this.$store.getters['auth/lang'];
  }
  get wordsDay(): string[] {
    if (this.lang === 'ja') {
      return ['日', '月', '火', '水', '木', '金', '土'];
    } else {
      return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    }
  }
  get note(): string {
    const text = `※当該条件でご予約いただけるホテル・プランのみ表示しています。<br>
        ※表示は、お客様の検索時点での空室状況並びに最安値価格となります。<br>
        ※予約操作中に満室となった場合、ご予約を承れない可能性がございます。<br>
        ※検索時点でのご予約状況により、最安値価格が変動する可能性がございます。<br>
        ※表示価格は税込み表示となります。<br>
        ※表示価格は選択条件における最安値の総額価格を表示しています。`;
    return text;
  }
  @Emit()
  private backToSelect(): void {
    this.$store.commit('today/hotels', []);
  }
  private mounted() {
    window.gtag('event', 'page_view', {
      page_location: '/today/result',
      page_path: '/today/result',
      page_title: '今夜の宿泊予約(最安値価格表示あり)【検索結果】',
    });
  }
}
