import { Component, Watch, Vue, Emit } from 'vue-property-decorator';
import store from '@/store';
import $ from 'jquery';
import axios, {AxiosResponse, AxiosError} from 'axios';
import SmoothScroll from 'smooth-scroll';
import ButtonLang from '@/components/button/ButtonLang.vue';
@Component({
  components: {
    ButtonLang,
  },
})
export default class DefaultLayout extends Vue {
  private menuData: LatestType[] = [];
  private updateDate: string|null = null;

  get specialofferUpdateVal(): number {
    let count: number = 0;
    const result = this.menuData.find( ({ id }) => id === 'specialoffer' );
    if (result) {
      count = result.value;
    }
    return count;
  }

  get campaignUpdateVal(): number {
    let count: number = 0;
    const result = this.menuData.find( ({ id }) => id === 'campaign' );
    if (result) {
      count = result.value;
    }
    return count;
  }

  get newsUpdateVal(): number {
    let count: number = 0;
    const result = this.menuData.find( ({ id }) => id === 'news' );
    if (result) {
      count = result.value;
    }
    return count;
  }

  get pickupUpdateVal(): number {
    let count: number = 0;
    const result = this.menuData.find( ({ id }) => id === 'pick-up' );
    if (result) {
      count = result.value;
    }
    return count;
  }

  get menu() {
    return {
      specialoffer: this.specialofferUpdateVal,
      campaign: this.campaignUpdateVal,
      news: this.newsUpdateVal,
      pickup: this.pickupUpdateVal,
    };
  }

  get userName(): string {
    return this.$store.getters['auth/name'];
  }
  get userNameEn(): string {
    return this.$store.getters['auth/nameEn'];
  }
  get lang(): string {
    return this.$store.getters['auth/lang'];
  }
  get loaded(): boolean {
    return this.$store.state.loaded;
  }
  get isLogin(): boolean {
    return !!this.$store.getters['auth/token'];
  }
  get isLogedin(): boolean {
    return (this.$store.getters['auth/name']) ? true : false;
  }
  get siteName(): string {
    if (this.lang === 'ja') {
      return process.env.VUE_APP_SITE_NAME;
    } else {
      return process.env.VUE_APP_SITE_NAME_EN;
    }
  }
  get description(): string {
    if (this.lang === 'ja') {
      return process.env.VUE_APP_DISC;
    } else {
      return process.env.VUE_APP_DISC_EN;
    }
  }
  get keywords(): string {
    if (this.lang === 'ja') {
      return process.env.VUE_APP_KEYWORD;
    } else {
      return '';
    }
  }

  @Emit()
  private setMenuData(data: ApiLatest): void {
    this.updateDate = data.date;
    this.menuData = data.type;
  }

  @Emit()
  private async getMenuData() {
    const result = await axios({
      method: 'get',
      url: `/api/wp-json/31corp/v1/latest`,
      headers: { Authorization: 'Bearer ' + store.getters['auth/token']},
    })
    .then( (response: AxiosResponse<ApiLatest>) => {
      this.setMenuData(response.data);
      return true;
    })
    .catch( (e: AxiosError) => {
      console.log(e);
      return false;
    });
    return result;
  }

  @Emit()
  private openModalSearchNavi(): void {
    // const $target: any = this.$refs.modalSearchNavi;
    // if (typeof $target !== 'undefined') {
    //   $target.open();
    // }
    store.commit('searchmodal/open', 'ModalSearchnavi');
  }

  private mounted() {
    this.getMenuData();
    const scroll = new SmoothScroll('a[href^="#"]', {
      ignore: '[href="#"]',
      updateURL: false,
    });
    const route: any = this.$route;
    // console.log(route)
    if (typeof route.hash !== 'undefined' && route.hash === '#search') {
      this.openModalSearchNavi();
    }
  }
}
