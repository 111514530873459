import { Component, Prop, Emit, Watch, Vue } from 'vue-property-decorator';
// @ is an alias to /src
import store from '@/store';
import axios, {AxiosResponse, AxiosError} from 'axios';
import $ from 'jquery';

@Component
export default class HomeHistory extends Vue {
  private name = 'homehistory';
  private list: HotelDetail[] = [];
  private firstItemsLength = 6;
  private length: number|null = null;
  private isOpend = false;
  get histories() {
    return this.$store.getters['history/hotels'];
  }
  get showMore() {
    if (this.list.length > this.firstItemsLength) {
      return true;
    } else {
      return false;
    }
  }
  @Emit()
  private setList(list: HotelDetail[]): void {
    this.list = this.list.concat(list);
  }
  @Emit()
  private delAllItems(): void {
    const history = this.$store.getters['history/hotels'];
    store.commit('history/remove', history);
  }
  @Emit()
  private async getMore() {
    if (this.isOpend) {
      this.isOpend = false;
    } else {
      this.isOpend = true;
    }
    this.$nextTick(() => {
      const list: any = this.$refs.list;
      if (!list) {
        return;
      }
      const $list: any = $(list);
      if (this.isOpend) {
        $list.find('.item:hidden').stop().slideDown();
      } else {
        $list.find('.item:gt(5)').stop().slideUp();
      }
    });
  }
  @Emit()
  private async getEntries() {
    const lang = this.$store.getters['auth/lang'];
    const getHotels = this.histories.filter((num: string, index: number) => {
      if (index >= this.list.length) {
        if (!this.length) {
          return num;
        } else if (index < this.length) {
          return num;
        }
      }
    });
    const hotels = this.histories.join(',');
    if (hotels.length > 0) {
      const result = await axios({
        method: 'get',
        url: `/api/wp-json/31corp/v1/hotels?hotel=${encodeURI(hotels)}&lang=${lang}`,
        headers: { Authorization: 'Bearer ' + this.$store.getters['auth/token']},
      })
      .then( (response: AxiosResponse) => {
        this.setList(response.data.hotels);
      })
      .catch( (e: AxiosError<ApiHotels>) => {
        console.log(e);
      });
    }
    return this.list;
  }
  @Watch('$store.state.history.hotels', { deep: true })
  private onChangeHistoryStatus(newHotels: string[], oldHotels: string[]) {
    const removeHotels: string[] = oldHotels.filter((itemNew) => {
      if (newHotels.indexOf(itemNew) === -1) {
        return itemNew;
      }
    });
    this.list = this.list.filter((hotel: HotelDetail) => {
      const removeFlg = (slug: string) => {
        if (removeHotels.indexOf(slug) === -1) {
          return false;
        } else {
          return true;
        }
      };
      if (!removeFlg(hotel.slug)) {
        return hotel;
      }
    });
  }
}
