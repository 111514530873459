import axios, {AxiosResponse, AxiosError} from 'axios';
export const Searchdate = {
  namespaced: true,
  state: {
    currentD: new Date(),
    valueD: new Date(),
    reserveD: new Date(),
    maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    valueStay: 0,
    valuePeople: 0,
    reserveList: [],
    area: null,
  },
  getters: {
    reserveList: (state: any) => {
      return state.reserveList;
    },
    reserveD: (state: any) => {
      return state.reserveD;
    },
    maxDate: (state: any) => {
      return state.maxDate;
    },
    currentD: (state: any) => {
      return state.currentD;
    },
    valueD: (state: any) => {
      return state.valueD;
    },
    valueStay: (state: any) => {
      return state.valueStay;
    },
    valuePeople: (state: any) => {
      return state.valuePeople;
    },
    area: (state: any) => {
      return state.area;
    },
    htlDatas: (state: any, getters: any, rootState: any, rootGetters: any) => {
      return rootGetters['search/data'];
    },
    token: (state: any, getters: any, rootState: any, rootGetters: any) => {
      return rootGetters['auth/token'];
    },
    lang: (state: any, getters: any, rootState: any, rootGetters: any) => {
      return rootGetters['auth/lang'];
    },
  },
  mutations: {
    setReserveList: (state: any, data: ReservableList) => {
      state.reserveList = data;
    },
    setReserveD: (state: any, data: Date) => {
      state.reserveD = new Date(data.getFullYear(), data.getMonth(), data.getDate(), 0, 0);
    },
    setCurrentD: (state: any, data: Date) => {
      state.currentD = data;
    },
    setValueD: (state: any, data: Date) => {
      state.valueD = data;
    },
    setValueStay: (state: any, data: number) => {
      state.valueStay = data;
    },
    setValuePeople: (state: any, data: number) => {
      state.valuePeople = data;
    },
    setArea: (state: any, str: string) => {
      state.area = str;
    },
  },
  actions: {
    getReserveList: async (cxt: any, params: any) => {
      const zero = (num: number): string => {
        const ret = ( '00' + num ).slice( -2 );
        return `${ret}`;
      };
      const valueYear = params.startDate.getFullYear();
      const valueMonth = params.startDate.getMonth() + 1;
      const valueDate = params.startDate.getDate();
      const valueStay = cxt.getters.valueStay;
      const valuePeople = cxt.getters.valuePeople;
      const htlIds = [];
      for (const value of cxt.getters.htlDatas) {
        htlIds.push(value.htl_id);
      }
      if (params.area) {
        cxt.commit('setArea', params.area);
      }
      const result = await axios({
        method: 'get',
        url: `/api/wp-json/31corp/v1/reservable?vacancy_date=${valueYear}/${zero(valueMonth)}/${zero(valueDate)}&vacancy_days=${valueStay + 1}&man_count=${valuePeople + 1}&area=${cxt.getters.area}&lang=${cxt.getters.lang}`,
        headers: { Authorization: 'Bearer ' + cxt.getters.token},
      })
      .then( (response: AxiosResponse<ApiReservable>) => {
        cxt.commit('setReserveD', params.startDate);
        const reserveList = response.data.hotels.map((value: any) => {
          const nullObj = {
            date: '',
            day: '',
            reservable: false,
            price: '',
            url: '',
          };
          const nullArr = [nullObj, nullObj, nullObj, nullObj, nullObj, nullObj, nullObj];
          if (typeof value.schedule !== "undefined" && value.schedule.length > 0) {
            if (cxt.getters.lang === 'en') {
              // console.log(value.schedule)
              value.schedule = value.schedule.map((element: any) => {
                if (element && typeof element.url !== 'undefined') {
                  element.url = element.url.replace('https://go-gardenhotels.reservation.jp/ja/', 'https://go-gardenhotels.reservation.jp/en/');
                }
                return element;
              })
            }
            return value;
          } else {
            return {heading: value.detail.name, schedule: nullArr, detail: value.detail };
          }
        });
        cxt.commit('setReserveList', reserveList);
        return response;
      })
      .catch( (e: AxiosError) => {
        console.log(e);
      });
      return result;
    },
  },
};
