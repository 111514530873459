
import { Vue, Component, Emit } from 'vue-property-decorator';
import $ from 'jquery';
import store from '@/store';
import axios, {AxiosResponse, AxiosError} from 'axios';

@Component
export default class FloatingBanner extends Vue {
  private fBanner: BannerData[] = [];
  private showFBanner: boolean = true;
  get banner(): BannerData {
    const max = this.fBanner.length;
    const min = 0;
    const ran = Math.floor(Math.random() * ( (max) - min ) + min);
    return this.fBanner[ran];
  }

  get token(): boolean {
    return this.$store.getters['auth/token'];
  }

  get hasFbanner(): boolean {
    return (this.fBanner.length > 0 && this.showFBanner) ? true : false;
  }

  @Emit()
  private closefBanner() {
    this.showFBanner = false;
    this.$emit('changeFbannerVar', false);
  }

  @Emit()
  private async getfBanner() {
    let headers: {Authorization?: string} = {};
    if (headers) {
      headers = { Authorization: 'Bearer ' + this.token};
    }
    const resBanner: BannerData[] = [];
    const res = await axios.get(`/api/wp-json/31corp/v1/banners?cat=is-login`, {headers});
    if (res.status === 200) {
      this.fBanner = res.data;
      this.$emit('changeFbannerVar', this.hasFbanner);
    }
  }

  private mounted() {
    this.getfBanner();
  }
}
