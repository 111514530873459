
import { Component, Vue } from 'vue-property-decorator';
// @ is an alias to /src
import store from '@/store';

@Component
export default class CommonHeaderEn extends Vue {
  private name = 'CommonHeaderEn';
  get isShow(): boolean {
    return this.$store.getters.loaded;
  }
}
