import Vue from "vue";
import VueRouter from "vue-router";
import axios, { AxiosResponse, AxiosError } from "axios";
import store from "@/store";
import { RouterJa } from "./ja";
import { RouterEn } from "./en";

// Vue.use(VueHead)
Vue.use(VueRouter);
const routes = [
  {
    // 全てにマッチします
    path: "*",
    beforeEnter: (to: any, from: any, next: any) => {
      next("/");
    },
  },
  {
    path: "/logout",
    alias: "/en/logout",
    beforeEnter: async (to: any, from: any, next: any) => {
      await store.dispatch("auth/logout").then((response: any) => {
        if (response) {
          let url = "/" + store.getters["auth/lang"];
          if (store.getters["auth/lang"] === "ja") {
            url = "";
          }
          next(url + "/login");
        } else {
          next(from.fullPath);
        }
      });
    },
  },
  {
    path: "/maintenance",
    name: "maintenance",
    meta: {
      type: "website",
      layout: "none",
      isPublic: true,
    },
    // beforeEnter: (to: any, from: any, next: any) => {
    //   if (process.env.VUE_APP_MAINTENANCE === "true") {
    //     next();
    //   } else {
    //     next("/");
    //   }
    // },
    component: () =>
      import(/* webpackChunkName: "maintenance" */ "../views/Maintenance.vue"),
  },
  RouterJa,
  {
    path: "/login",
    meta: {
      isPublic: true,
      title: "ログイン画面",
      type: "website",
    },
    component: () =>
      import(/* webpackChunkName: "Ja" */ "../layout/DefaultLayoutLogin.vue"),
    children: [
      {
        path: "",
        component: () =>
          import(/* webpackChunkName: "login" */ "../views/Login.vue"),
        beforeEnter: (to: any, from: any, next: any) => {
          store.dispatch("head/updateTitle", "ログイン");
          if (store.getters["auth/token"] !== null) {
            next({ path: "/" });
          } else {
            next();
          }
        },
      },
    ],
  },
  {
    path: "/bestprice",
    redirect: "/pages/bestprice",
  },
  {
    path: "/commonenq_fin",
    redirect: "/pages/commonenq_fin",
  },
  RouterEn,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  const $to: any = to;
  let params: any = {};
  // console.log(process.env.NODE_ENV);
  if (process.env.NODE_ENV !== "development" && location.protocol === "http:") {
    location.replace(location.href.replace(/http:/, "https:"));
  }

  const regex = /^\/en($|\/)/;
  if (regex.test(to.fullPath)) {
    store.commit("auth/updateLang", "en");
  } else {
    store.commit("auth/updateLang", "ja");
  }
  store.dispatch("head/updateTitle", $to.meta.title);
  store.dispatch("head/updateDescription", $to.meta.description);
  store.dispatch("head/updateKeywords");
  store.dispatch("head/updateOgSiteName");
  store.dispatch("head/updateOgType", $to.meta.type);
  store.dispatch("head/updateOgUrl", to.fullPath);
  // store.dispatch('head/updateOgImage', to.meta.ogImage);
  store.dispatch("head/updateOgLocale");
  if (typeof $to.query !== "undefined") {
    params = $to.query;
  }
  if (typeof params.line_id_token !== "undefined") {
    try {
      const resAutoLogin = await store.dispatch("auth/autoLogin", params);
    } catch (error) {
      console.error(error);
    }
    if (typeof params.redirect !== "undefined") {
      delete params.line_id_token;
      next({
        path: params.redirect,
        query: params,
        replace: true,
      });
    } else {
      next({ path: "/", replace: true });
    }
    return;
  }
  let isLogin: boolean = await store.dispatch("auth/isLoggedin", params);

  if (isLogin) {
    next();
    return;
  }
  if (to.matched.some((page) => page.meta.isPublic)) {
    next();
    return;
  }
  let langPath = "/" + store.getters["auth/lang"];
  if (store.getters["auth/lang"] === "ja") {
    langPath = "";
  }
  store.commit("loaded", true);
  next({
    path: langPath + "/login",
    query: { redirect: to.fullPath },
  });
});

router.afterEach((to, from) => {
  if (store.getters["auth/lang"] === "ja") {
    document.documentElement.lang = "ja";
  } else {
    document.documentElement.lang = "en";
  }
  document.title = store.getters["head/title"];
  document
    .querySelector(`meta[name='description']`)!
    .setAttribute("content", store.getters["head/description"]);
  document
    .querySelector(`meta[name='keywords']`)!
    .setAttribute("content", store.getters["head/keywords"]);
  document
    .querySelector(`meta[property='og:site_name']`)!
    .setAttribute("content", store.getters["head/og_site_name"]);
  document
    .querySelector(`meta[property='og:title']`)!
    .setAttribute("content", store.getters["head/og_title"]);
  document
    .querySelector(`meta[property='og:description']`)!
    .setAttribute("content", store.getters["head/og_description"]);
  document
    .querySelector(`meta[property='og:type']`)!
    .setAttribute("content", store.getters["head/og_type"]);
  // document.querySelector(`meta[property='og:image']`)!.setAttribute('content', store.getters['head/og_image']);
  document
    .querySelector(`meta[property='og:url']`)!
    .setAttribute("content", store.getters["head/og_url"]);
  document
    .querySelector(`meta[property='og:locale']`)!
    .setAttribute("content", store.getters["head/og_locale"]);
  window._satellite.track("common");
  window.gtag("config", process.env.VUE_APP_GAID, {
    custom_map: {
      dimension1: "MembersName",
      dimension2: "searchNoResult",
    },
    page_title: store.getters["head/title"],
    page_path: to.path,
    MembersName: store.getters["auth/name"],
  });
  window.gtag("set", "user_properties", {
    CorpUserType: store.getters["auth/name"],
  });
  window._lt("send", "pv", [process.env.VUE_APP_LINE_TAGID]);
  if (
    to.path !== "/logout" &&
    to.path !== "/en/logout" &&
    store.getters["auth/name"]
  ) {
    // window.gtag('config', 'G-17MV7EM5EZ', {
    //   'user_id': 'USER_ID'
    // })
    // GTMのタグが準備される前は`window.dataLayer`が存在しないため、一度空の配列を代入しておく。(pushもArray prototypeのpushを使う)
    // GTMのタグが準備された後は、GTMに送信する「dataLayerが持っているpushメソッド」が使えるようになる
    // window.dataLayer = window.dataLayer || [];
    // window.dataLayer.push({
    //   // 'user_id': store.getters["auth/name"],
    //   'CorpName': store.getters["auth/name"],
    //   // user_id: store.getters["auth/name"],
    //   // CorpUserType: store.getters["auth/name"],
    // });
    window.gtag("event", "LoginMember", {
      MembersName: store.getters["auth/name"],
    });
    window.gtag('event', 'page_view', {
      page_title: store.getters["head/title"],
    });
  }
});

export default router;
