
import { Vue, Component } from 'vue-property-decorator';

const settingJa = {
  link: `/pages/2024mghrec`,
  text: `【さらにお得！】<br>MGH Rewards Club<br class="pc">法人会員連携`
}

const settingEn = {
  link: `/en/pages/2024mghrec`,
  text: `Let's link your MGH Rewards Club Account and Corporate Account`
}

@Component
export default class ButtonRewardsClub extends Vue {
  get lang(): string {
    return this.$store.getters['auth/lang'];
  }

  get isGroup(): boolean {
    return this.$store.getters['auth/group'];
  }

  get word() {
    if(this.lang === 'ja') {
      return settingJa;
    }
    return settingEn;
  }
}
