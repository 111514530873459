
import { Component, Mixins } from 'vue-property-decorator';
import HomeHistoryItem from './';
import BoxListItem from '@/components/box/BoxListItem/BoxListItem.vue';
import ButtonHistoryRemove from '@/components/button/ButtonHistoryRemove.vue';

@Component({
  components: {
    BoxListItem,
    ButtonHistoryRemove,
  },
})
export default class HomeHistoryItemJa extends Mixins(HomeHistoryItem) {
}
