import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import store from '@/store';
// @ is an alias to /src
import HistoryDirective from '@/directives/history';
import GaSendSite from '@/directives/GaSendSite';

@Component({
  directives: {
    history: HistoryDirective,
    gasendsite: GaSendSite,
  },
})
export default class BoxListItem extends Vue {
  private　name = 'boxlistitem';
  @Prop()
  private datas!: {
    id: string,
    slug: string,
    kind: string,
    name: string,
    image: string,
    siteUrl: string,
    stayingPlans: any[],
  };
  get isActiveResortPlanBtn(): boolean {
    if (this.datas.stayingPlans && this.datas.stayingPlans.length === 1) {
      return true;
    } else {
      return false;
    }
  }
  get lang(): string {
    return store.getters['auth/lang'];
  }
  get btReserveTo(): string {
    if (this.lang === 'ja') {
      return '/resort/name/' + this.datas.slug;
    } else {
      return '/' + store.getters['auth/lang'] + '/resort/name/' + this.datas.slug;
    }
  }
}
