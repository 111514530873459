
import { Component, Vue, Mixins } from 'vue-property-decorator';
import FormKeywordRecommend from '@/components/form/FormKeywordRecommend/';
const Words: Dictionary = {
  placeholder: 'Enter hotel name or location',
  history: 'Search history',
  keyword: 'Popular keywords',
};
@Component
export default class FormKeyword extends Mixins(FormKeywordRecommend) {
  private words = Words;
}
