import { Component, Watch, Emit, Prop, Vue } from 'vue-property-decorator';
import store from '@/store';
// @ is an alias to /src

@Component
export default class ButtonHistory extends Vue {
  private　name = 'buttonhistory';
  private history: boolean = false;
  @Prop()
  private slug!: string;
  @Emit()
  private addHistories() {
    store.commit('history/add', this.slug);
  }
  @Emit()
  private remove() {
    store.commit('history/remove', this.slug);
  }
  @Emit()
  private changeHistories() {
    const myHistorys = this.$store.getters['history/hotels'];
    if (myHistorys.indexOf(this.slug) === -1) {
      this.addHistories();
    } else {
      this.remove();
    }
  }
  @Watch('$store.state.history', { immediate: true, deep: true })
  private onChangeHistoriesStatus() {
    const myHistorys = this.$store.getters['history/hotels'];
    if (myHistorys.indexOf(this.slug) === -1) {
      this.history = false;
    } else {
      this.history = true;
    }
  }
  private mounted() {
    const myHistorys = this.$store.getters['history/hotels'];
    if (myHistorys.indexOf(this.slug) === -1) {
      this.history = false;
    } else {
      this.history = true;
    }
  }
}
