
import { Component, Mixins } from "vue-property-decorator";
import NaviSerchnaviAreaLinks from "./";
const Words: Dictionary = {
  btn01: {
    link: "/hotel/area/sapporo",
    name: "札幌",
  },
  btn02: {
    link: "/hotel/area/sendai",
    name: "仙台",
  },
  btn03: {
    link: "/hotel/area/tokyo",
    name: "東京",
  },
  btn04: {
    link: "/hotel/area/chiba",
    name: "千葉",
  },
  btn05: {
    link: "/hotel/area/nagoya",
    name: "名古屋",
  },
  btn06: {
    link: "/resort/area/mie",
    name: "三重",
  },
  btn07: {
    link: "/hotel/area/kanazawa",
    name: "金沢",
  },
  btn08: {
    link: "/hotel/area/kyoto",
    name: "京都",
  },
  btn09: {
    link: "/hotel/area/osaka",
    name: "大阪",
  },
  btn10: {
    link: "/hotel/area/hiroshima-okayama",
    name: "広島・岡山",
  },
  btn11: {
    link: "/hotel/area/fukuoka-kumamoto",
    name: "福岡・熊本",
  },
  btn12: {
    link: "/resort/area/okinawa",
    name: "沖縄",
  },
  btn13: {
    link: "/hotel/area/taiwan",
    name: "台湾",
  },
  btn14: {
    link: "/hotel/area/shizuoka",
    name: "静岡",
  },
  btn15: {
    link: "/hotel/area/yokohama",
    name: "横浜",
  },
  btn16: {
    link: "/hotel/area/hawaii",
    name: "ハワイ",
  },
};
@Component
export default class NaviSerchnaviAreaLinksJa extends Mixins(
  NaviSerchnaviAreaLinks
) {
  private words = Words;
}
