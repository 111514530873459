import { DirectiveOptions, DirectiveFunction, VNode } from 'vue';
import { DirectiveBinding } from 'vue/types/options';
import store from '@/store';
/**
 *
 */
const bind: DirectiveFunction = (el: HTMLElement, binding: DirectiveBinding, vnode: VNode, oldVnode: VNode) => {
  // 具体的な実装
  const clickEvent = () => {
    store.commit('history/add', binding.value);
  };
  el.addEventListener('click', clickEvent);
};

/**
 *
 */
const unbind: DirectiveFunction = (el: HTMLElement, binding: DirectiveBinding, vnode: VNode, oldVnode: VNode) => {
  // 具体的な実装
};

/**
 *
 */
const IntersectDirective: DirectiveOptions = {
  bind,
  unbind,
};

export default IntersectDirective;
