import { DirectiveOptions, DirectiveFunction, VNode } from 'vue';
import { DirectiveBinding } from 'vue/types/options';
/**
 *
 */
const bind: DirectiveFunction = (el: HTMLElement, binding: DirectiveBinding, vnode: VNode, oldVnode: VNode) => {
  // 具体的な実装
  const clickEvent = () => {
    let siteName = 'MGH_CEL' + binding.value.htl_id;
    if ( binding.value.slug === 'haimurubushi' ) {
      siteName = 'HAIMURU';
    } else if ( binding.value.slug === 'nemuresort' ) {
      siteName = 'NEMU';
    } else if ( binding.value.slug === 'shiojitei' ) {
      siteName = 'SHIOJITEI';
    } else if ( binding.value.slug === 'toba' ) {
      siteName = 'TOBA';
    } else if ( binding.value.slug === 'harekuraini-okinawa' ) {
      siteName = 'HAREKULANIOKINAWA';
    }
    if (process.env.NODE_ENV === 'development') {
      console.log('event', 'sendSite', { event_category: 'cv', event_label: siteName, value: 1 });
    }
    window.gtag('event', 'sendSite', { event_category: 'cv', event_label: siteName, value: 1 });
  };
  el.addEventListener('click', clickEvent);
};

/**
 *
 */
const unbind: DirectiveFunction = (el: HTMLElement, binding: DirectiveBinding, vnode: VNode, oldVnode: VNode) => {
  // 具体的な実装
};

/**
 *
 */
const IntersectDirective: DirectiveOptions = {
  bind,
  unbind,
};

export default IntersectDirective;
