import { Component, Vue, Emit } from 'vue-property-decorator';
import FormKeywordRecommend from '@/components/form/FormKeywordRecommend/FormKeywordRecommend.vue';
@Component({
  components: {
    FormKeywordRecommend,
  },
})
export default class NaviSerchnaviKeyword extends Vue {
  private name = 'NaviSerchnaviKeyword';
}
