import axios, {AxiosResponse, AxiosError} from 'axios';
const historyMax = 10;
const area = (areaname: string): any => {
  switch (areaname) {
    case 'sapporo':
      return { ja: '札幌', en: 'Sapporo' };
      break;
    case 'sendai':
      return { ja: '仙台', en: 'Sendai' };
      break;
    case 'tokyo':
      return { ja: '東京', en: 'Tokyo' };
      break;
    case 'chiba':
      return { ja: '千葉', en: 'Chiba' };
      break;
    case 'nagoya':
      return { ja: '名古屋', en: 'Nagoya' };
      break;
    case 'mie':
      return { ja: '三重', en: 'Mie' };
      break;
    case 'kanazawa':
      return { ja: '金沢', en: 'Kanazawa' };
      break;
    case 'kyoto':
      return { ja: '京都', en: 'Kyoto' };
      break;
    case 'osaka':
      return { ja: '大阪', en: 'Osaka' };
      break;
    case 'hiroshima-okayama':
      return { ja: '広島・岡山', en: 'Hiroshima/Okayama' };
      break;
    case 'fukuoka-kumamoto':
      return { ja: '福岡・熊本', en: 'Fukuoka/Kumamoto' };
      break;
    case 'okinawa':
      return { ja: '沖縄', en: 'Okinawa' };
      break;
    case 'taiwan':
      return { ja: '台湾', en: 'Taiwan' };
      break;
    case 'shizuoka':
      return { ja: '静岡', en: 'Shizuoka' };
      break;
    case 'yokohama':
      return { ja: '横浜', en: 'Yokohama' };
      break;
    case 'hawaii':
      return { ja: 'ハワイ', en: 'Hawaii' };
      break;
    default:
      return '';
  }
};

export const Search = {
  namespaced: true,
  state: {
    loaded: false,
    data: [],
    hits: null,
    length: null,
    offset: 0,
    history: [],
    word: null,
    isError: 0,
    recommend: [],
    key: null,
  },
  getters: {
    loaded: (state: any) => {
      return state.loaded;
    },
    data: (state: any) => {
      return state.data;
    },
    hits: (state: any) => {
      return state.hits;
    },
    offset: (state: any) => {
      return state.offset;
    },
    length: (state: any) => {
      return state.length;
    },
    history: (state: any) => {
      return state.history;
    },
    word: (state: any) => {
      return state.word;
    },
    token: (state: any, getters: any, rootState: any, rootGetters: any) => {
      return rootGetters['auth/token'];
    },
    isError: (state: any) => {
      return state.isError;
    },
    recommend: (state: any) => {
      return state.recommend;
    },
    key: (state: any) => {
      return state.key;
    },
    account: (state: any, getters: any, rootState: any, rootGetters: any) => {
      return rootGetters['auth/id'];
    },
    lang: (state: any, getters: any, rootState: any, rootGetters: any) => {
      return rootGetters['auth/lang'];
    },
  },
  mutations: {
    resetData: (state: any) => {
      state.data = [];
      state.hits = null;
      state.length = null;
      state.offset = 0;
      state.isError = 0;
    },
    logout: (state: any) => {
      state.loaded = false;
      state.data = [];
      state.hits = null;
      state.length = null;
      state.offset = 0;
      state.history = [];
      state.word = null;
      state.isError = 0;
      state.recommend = [];
      state.key = null;
    },
    setLoaded: (state: any, data: boolean) => {
      state.loaded = data;
    },
    setData: (state: any, data: HotelDetail) => {
      state.data = data;
    },
    setHits: (state: any, hits: number) => {
      state.hits = Number(hits);
    },
    setOffset: (state: any, offset: number) => {
      state.offset = Number(offset);
    },
    setLength: (state: any, length: number|null) => {
      state.length = Number(length);
    },
    setHistory: (state: any, word: string|string[]) => {
      let addWord: string[];
      if (!Array.isArray(word)) {
        addWord = [word];
      } else {
        addWord = word;
      }
      const addWords = addWord.filter((item) => {
        if (state.history.indexOf(item) === -1) {
          return item;
        }
      });
      const res = state.history.concat(addWords);
      state.history = res;
      if (state.history.length > historyMax) {
        state.history.splice(0, state.history.length - historyMax);
      }
      localStorage[state.key] = String(state.history);
    },
    setWord: (state: any, word: string) => {
      state.word = String(word);
    },
    setError: (state: any, num: number) => {
      state.isError = num;
    },
    setRecommend: (state: any, arr: string[]) => {
      state.recommend = arr;
    },
    setKey: (state: any, account: string) => {
      state.key = 'searchHistory' + account;
    },
  },
  actions: {
    historyInit: (cxt: any, params: any) => {
      cxt.commit('setKey', String(cxt.getters.account));
      if (localStorage[cxt.getters.key]) {
        const searchHistories = localStorage[cxt.getters.key].split(',');
        cxt.commit('setHistory', searchHistories);
      }
    },
    getEntry: async (cxt: any, params: any) => {
      cxt.commit('setLoaded', false);
      let url = `/api/wp-json/31corp/v1/hotels?`;
      if (params.area) {
        url += `area=${encodeURI(params.area)}`;
      } else if (params.hotel) {
        url += `hotel=${encodeURI(params.hotel)}`;
      } else if (params.keyword) {
        url += `q=${encodeURI(params.keyword)}`;
      }
      let offset = 0;
      offset = (params.offset - 1) * params.length;
      if (offset < 0) {
        offset = 0;
      }
      url += `&offset=${offset}`;
      if (params.length) {
        url += `&length=${params.length}`;
      }
      if (params.brand) {
        url += `&brand=${encodeURI(params.brand)}`;
      }
      url += `&lang=${cxt.getters.lang}`;
      const res = await axios.get(url, {
        headers: { Authorization: 'Bearer ' + cxt.getters.token},
      })
      .then( (response: AxiosResponse<ApiHotels>) => {
        if (params.keyword && response.data.hits > 0) {
          cxt.commit('setHistory', String(params.keyword));
        }
        let title: string = '';
        if (params.name === 'hotel_area_pages' || params.name === 'hotel_area') {
          title = area(String(params.area)).ja + 'エリアのホテル';
        } else if (params.name === 'hotel_area_en' || params.name === 'hotel_area_pages_en') {
          title = `Hotels in ${area(String(params.area)).en}`;
        } else if (params.name === 'hotel_all' || params.name === 'hotel_all_pages') {
          title = '全てのホテル';
        } else if (params.name === 'hotel_all_en' || params.name === 'hotel_all_pages_en') {
          title = 'All Hotels';
        } else if (params.name === 'resort' || params.name === 'resort_pages') {
          title = '全てのリゾートホテル';
        } else if (params.name === 'resort_en' || params.name === 'resort_pages_en') {
          title = 'All Resort Hotels';
        } else if (params.name === 'resort_area' || params.name === 'resort_area_pages') {
          title = `${area(String(params.area)).ja}エリアのリゾートホテル`;
        } else if (params.name === 'resort_area_2' || params.name === 'resort_area_pages_en') {
          title = `Resort Hotel in ${area(String(params.area)).en} Area`;
        } else if (
          params.name === 'hotel_name' ||
          params.name === 'resort_name' ||
          params.name === 'hotel_name_en' ||
          params.name === 'resort_name_en'
        ) {
          title = String(response.data.hotels[0].name);
        } else if (params.name === 'search' || params.name === 'search_p') {
          title = 'キーワード ' + String(params.keyword) + 'で検索中';
        } else if (params.name === 'search_en' || params.name === 'search_p_en') {
          title = `Search results for keyword "${String(params.keyword)}"`;
        }
        cxt.commit('setWord', title);
        cxt.dispatch('head/updateTitle', title, {root: true});
        cxt.commit('setOffset', Number(params.offset));
        cxt.commit('setLength', Number(params.length));
        cxt.commit('setHits', Number(response.data.hits));
        cxt.commit('setData', response.data.hotels);
        cxt.commit('setError', 0);
        return response;
      })
      .catch( (error: AxiosError) => {
        cxt.commit('setError', 1);
        return false;
      });
      cxt.commit('setLoaded', true);
      return res;
    },
    getRecommend: (cxt: any, params: any) => {
      let url = '/api/wp-json/31corp/v1/recommend';
      url += `?lang=${cxt.getters.lang}`;
      const result = axios.get(url, {
        headers: { Authorization: 'Bearer ' + cxt.getters.token },
      })
      .then( (response) => {
        cxt.commit('setRecommend', response.data);
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
      return result;
    },
  },
};
