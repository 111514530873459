
import { Component, Mixins } from 'vue-property-decorator';
import store from '@/store';
import Core from './';
const WordsJa: Dictionary = {
  copy: `お気に入り登録の最大件数に達しましたので、最も古いお気に入りを削除します。`,
  alert: `次回以降、このアラートを表示しない。`,
};
const WordsEn: Dictionary = {
  copy: `Since the maximum number of favorites has been reached, the oldest favorite will be deleted .`,
  alert: `Do not notify from next time.`,
};
@Component
export default class ModalFavoritesAlert extends Mixins(Core) {
  get lang(): string {
    return store.getters['auth/lang'];
  }
  get words() {
    if (this.lang === 'ja') {
      return WordsJa;
    } else {
      return WordsEn;
    }
  }
}
