import { Component, Vue, Emit, Watch, Prop } from 'vue-property-decorator';
import store from '@/store';
import $ from 'jquery';
import remodal from 'remodal';

@Component
export default class ModalFavoritesAlert extends Vue {
  private name = 'modalfavoritesalert';
  private alertElm: any = null;
  get hideAlert(): boolean {
    return this.$store.getters['favorites/hideAlert'];
  }
  set hideAlert(bool: boolean) {
    this.$store.commit('favorites/setHideAlert', bool);
  }
  get alertState(): boolean {
    return this.$store.getters['favorites/alert'];
  }
  private mounted() {
    $(document).on('closed', '.remodal', (e) => {
      this.$store.commit('favorites/setAlert', false);
      this.$store.commit('favorites/saveHideAlert', this.$store.getters['favorites/hideAlert']);
    });
  }
  @Watch('alertState', {immediate: false})
  private onChangeFavoritesAlert(newBool: any, oldBool: any) {
    if ( newBool !== oldBool && newBool && !this.$store.getters['favorites/hideAlert']) {
      const tmp = remodal;
      this.alertElm = ($('[data-remodal-id=warning]') as any).remodal({ hashTracking: false });
      this.alertElm.open();
    } else {
      this.$store.commit('favorites/stashConfirm');
      this.$store.commit('favorites/setAlert', false);
    }
  }
  @Emit()
  private confirm(): void {
    this.$store.commit('favorites/stashConfirm');
  }
  @Emit()
  private cancel(): void {
    this.$store.commit('favorites/stashCancel');
  }
}
