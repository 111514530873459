
import { Component, Mixins } from 'vue-property-decorator';
import NaviSerchnavi from '@/components/navi/NaviSerchnavi/';
import NaviSerchnaviArea from '@/components/navi/NaviSerchnaviArea/NaviSerchnaviArea.vue';
import NaviSerchnaviKeyword from '@/components/navi/NaviSerchnaviKeyword/NaviSerchnaviKeyword.vue';
import NaviSerchnaviLink from '@/components/navi/NaviSerchnaviLink/NaviSerchnaviLink.vue';
const Words: Dictionary = {
  label: '宿泊エリアを選択してください',
};
@Component({
  components: {
    NaviSerchnaviArea,
    NaviSerchnaviKeyword,
    NaviSerchnaviLink,
  },
})
export default class NaviSerchnaviJa extends Mixins(NaviSerchnavi) {
  private words = Words;

  get isGroup(): boolean {
    return this.$store.getters['auth/group'];
  }
}
