
import { Component, Mixins } from 'vue-property-decorator';
import NaviSerchnaviKeyword from '@/components/navi/NaviSerchnaviKeyword';
import FormKeywordRecommend from '@/components/form/FormKeywordRecommend/FormKeywordRecommend.vue';
const Words: Dictionary = {
  label: 'キーワードから検索する',
};
@Component({
  components: {
    FormKeywordRecommend,
  },
})
export default class NaviSerchnaviKeywordJa extends Mixins() {
  private words = Words;
}
