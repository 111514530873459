
import { Component, Prop, Vue } from 'vue-property-decorator';
// @ is an alias to /src

interface PickupBox {
  id: string|null;
  date: string|null;
  title: string|null;
  images: string|null;
  hotelDetail: any[];
  thumbsize: any;
  newIcon: boolean;
}

@Component
export default class BoxPickup extends Vue {
  private　name = 'boxpickup';
  @Prop({ default: null })
  private images!: string|null;
  @Prop()
  private data!: PickupBox;
  get thumbimage() {
    if (this.images) {
      return this.images;
    } else {
      return this.data.thumbsize['330x200'];
    }
  }
  get hotelName(): string|boolean {
    if (this.data.hotelDetail.length > 0) {
      return this.data.hotelDetail[0].name;
    } else {
      return false;
    }
  }
}
