
import { Component, Mixins } from 'vue-property-decorator';
import NaviSerchnaviLink from '@/components/navi/NaviSerchnaviLink/';
const Words: Dictionary = {
  btn01: {
    link: '/en/resort/name/hotel-the-mitsui-kyoto',
    name: 'HOTEL THE MITSUI KYOTO',
  },
  btn02: {
    link: '/en/resort/name/harekuraini-okinawa',
    name: 'Halekulani OKINAWA',
  },
};
@Component
export default class NaviSerchnaviLinkEn extends Mixins(NaviSerchnaviLink) {
  private words = Words;
}
