
import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import axios, {AxiosResponse, AxiosError} from 'axios';
// tslint:disable-next-line:no-var-requires
const axiosJsonpAdapter: any = require('axios-jsonp');

@Component
export default class ButtonMovie extends Vue {
  private thumbnail: string = '';
  private movie: string = '';
  @Prop()
  private url!: string;
  @Emit()
  private clickMovieBtn(): void {
    this.$store.commit('movie/open', this.movie);
  }
  @Emit()
  private getVimeoThumb() {
    axios.get(
      `//vimeo.com/api/oembed.json?url=${this.url}`,
      {
        adapter: axiosJsonpAdapter,
      },
    )
    .then( (response: AxiosResponse) => {
      this.thumbnail = response.data.thumbnail_url;
    })
    .catch( (e: AxiosError) => {
      console.log(e);
    });
  }
  private created() {
    if (/https?:\/\/vimeo\.com/.test(this.url)) {
      const id = this.url.match(/vimeo\.com\/(.+)/);
      this.getVimeoThumb();
      this.movie = this.url;
    } else if (/https?:\/\/www\.youtube\.com\/watch\?v=.+/.test(this.url)) {
      const id = this.url.match(/youtube\.com\/watch\?v=(.+)/);
      if (typeof id !== 'undefined' && id !== null) {
        this.thumbnail = `//img.youtube.com/vi/${id[1]}/mqdefault.jpg`;
        this.movie = this.url;
      }
    } else if (/https?:\/\/youtu\.be\/.+/.test(this.url)) {
      const id = this.url.match(/youtu\.be\/(.+)/);
      if (typeof id !== 'undefined' && id !== null) {
        this.thumbnail = `//img.youtube.com/vi/${id[1]}/mqdefault.jpg`;
        this.movie = `https://www.youtube.com/watch?v=${id[1]}`;
      }
    } else {
      this.movie = this.url;
    }
  }
}
