export const Favorites = {
  namespaced: true,
  state: {
    hotels: [],
    alert: false,
    maxSave: 12,
    hideAlert: false,
    stash: [],
    key: null,
  },
  getters: {
    hotels: (state: any) => {
      return state.hotels;
    },
    alert: (state: any) => {
      return state.alert;
    },
    hideAlert: (state: any) => {
      return state.hideAlert;
    },
    key: (state: any) => {
      return state.key;
    },
    account: (state: any, getters: any, rootState: any, rootGetters: any) => {
      return rootGetters['auth/id'];
    },
  },
  mutations: {
    reset: (state: any, slug: string[]|string ) => {
      state.hotels = [];
      state.alert = false;
      state.hideAlert = false;
      state.stash = [];
      state.key = null;
      // localStorage.removeItem(state.key);
      // localStorage.removeItem(state.key + 'Alert');
    },
    add: (state: any, slug: string[]|string ) => {
      let addHotelCount: number = 0;
      let addSlug: string[];
      if (!Array.isArray(slug)) {
        addSlug = [slug];
      } else {
        addSlug = slug;
      }
      const addHotels = addSlug.filter((item) => {
        if (state.hotels.indexOf(item) === -1) {
          return item;
        }
      });
      addHotelCount = addHotels.length;
      if (state.hotels.length + addHotelCount > state.maxSave) {
        state.stash = addSlug;
        state.alert = true;
      } else {
        const result = addHotels.concat(state.hotels);
        state.hotels = result;
      }
      localStorage[state.key] = String(state.hotels);
    },
    remove: (state: any, slug: string) => {
      const result = state.hotels.filter((item: any) => {
        if (item !== slug) {
          return item;
        }
      });
      state.hotels = result;
      localStorage[state.key] = String(result);
    },
    setKey: (state: any, account: string) => {
      state.key = 'favorites' + account;
    },
    setAlert: (state: any, bool: boolean) => {
      state.alert = bool;
    },
    setHideAlert: (state: any, bool: number) => {
      state.hideAlert = Boolean(bool);
    },
    saveHideAlert: (state: any, bool: boolean) => {
      localStorage[state.key + 'Alert'] = (bool) ? 1 : 0;
    },
    stashCancel: (state: any) => {
      state.stash = [];
    },
    stashConfirm: (state: any) => {
      const concatHotels = state.stash.concat(state.hotels);
      concatHotels.splice(concatHotels.length - 1, concatHotels.length - state.hotels.length);
      state.hotels = concatHotels;
      localStorage[state.key] = String(state.hotels);
      state.stash = [];
    },
  },
  actions: {
    init: (cxt: any ) => {
      cxt.commit('setKey', String(cxt.getters.account));
      if (localStorage[cxt.getters.key]) {
        const favoritesArr = localStorage[cxt.getters.key].split(',');
        cxt.commit('add', favoritesArr);
      }
      if (localStorage[cxt.getters.key + 'Alert']) {
        cxt.commit('setHideAlert', Number(localStorage[cxt.getters.key + 'Alert']));
      }
    },
  },
};
