import store from '@/store';
import {endOfNumberPath, validatePathIsEndOfNumber} from './validate';

import Home from '../views/Home.vue';

export const RouterJa = {
  path: '/',
  component: () => import(/* webpackChunkName: "Ja" */ '../layout/DefaultLayout.vue'),
  children: [
    {
      path: '',
      component: Home,
      name: 'home',
      meta: {
        type: 'website',
      },
    },
    {
      path: 'hotel',
      redirect: '/hotel/1',
      props: {
        title1: 'SEARCH',
        title2: 'FIND THE HOTEL',
      },
      component: () => import(/* webpackChunkName: "search" */ '../components/layout/LayoutHasPagesTitle.vue'),
      children: [
        {
          path: ':p',
          name: 'hotel_all_pages',
          meta: {
            type: 'article',
          },
          component: () => import(/* webpackChunkName: "search" */ '../views/HotelSearch.vue'),
          beforeEnter: (to: any, from: any, next: any) => {
            validatePathIsEndOfNumber(to.path);
            next();
          },
        },
        {
          path: 'area/:area',
          redirect: 'area/:area/1',
          component: () => import(/* webpackChunkName: "search" */ '../views/HotelSearch.vue'),
          children: [
            {
              path: ':p',
              name: 'hotel_area_pages',
              meta: {
                type: 'article',
              },
              beforeEnter: (to: any, from: any, next: any) => {
                validatePathIsEndOfNumber(to.path);
                next();
              },
            },
          ],
        },
        {
          path: 'name/:name',
          name: 'hotel_name',
          meta: {
            type: 'article',
          },
          component: () => import(/* webpackChunkName: "search" */ '../views/HotelSearch.vue'),
        },
        {
          path: 'name/:name/map',
          name: 'hotel_map',
          meta: {
            type: 'article',
            title: 'MAP画面',
          },
          component: () => import(/* webpackChunkName: "search" */ '../views/Map.vue'),
        },
      ],
    },
    {
      path: 'search/:keyword',
      redirect: '/search/:keyword/1',
      props: {
        title1: 'KEYWORD',
        title2: 'FIND THE HOTEL',
      },
      component: () => import(/* webpackChunkName: "search" */ '../components/layout/LayoutHasPagesTitle.vue'),
      children: [
        {
          path: ':p',
          name: 'search_p',
          meta: {
            type: 'article',
          },
          component: () => import(/* webpackChunkName: "search" */ '../views/SearchKeyword.vue'),
          beforeEnter: (to: any, from: any, next: any) => {
            validatePathIsEndOfNumber(to.path);
            next();
          },
        },
      ],
    },
    {
      path: 'resort',
      redirect: '/resort/1',
      component: () => import(/* webpackChunkName: "search" */ '../components/layout/LayoutHasPagesTitle.vue'),
      props: {
        title1: 'SEARCH',
        title2: 'FIND THE HOTEL',
      },
      children: [
        {
          path: ':p',
          name: 'resort_pages',
          meta: {
            type: 'article',
            brand: 'resort',
          },
          component: () => import(/* webpackChunkName: "search" */ '../views/ResortSearch.vue'),
          beforeEnter: (to: any, from: any, next: any) => {
            validatePathIsEndOfNumber(to.path);
            next();
          },
        },
        {
          path: 'area/:area',
          redirect: 'area/:area/1',
          component: () => import(/* webpackChunkName: "search" */ '../views/ResortSearch.vue'),
          children: [
            {
              path: ':p',
              name: 'resort_area_pages',
              meta: {
                type: 'article',
                brand: 'resort',
              },
              beforeEnter: (to: any, from: any, next: any) => {
                validatePathIsEndOfNumber(to.path);
                next();
              },
            },
          ],
        },
        {
          path: 'name/:name',
          name: 'resort_name',
          component: () => import(/* webpackChunkName: "search" */ '../views/ResortSearch.vue'),
          meta: {
            type: 'article',
            brand: 'resort',
          },
        },
      ],
    },
    {
      path: 'pickup',
      meta: {
        type: 'article',
        category: 'pickup',
      },
      props: {
        title1: 'PICK UP',
        title2: 'おすすめ情報',
      },
      component: () => import(/* webpackChunkName: "pickup" */ '../components/layout/LayoutHasPagesTitle.vue'),
      children: [
        {
          path: '',
          component: () => import(/* webpackChunkName: "campaign" */ '../views/pickup/Index.vue'),
          beforeEnter: (to: any, from: any, next: any) => {
            const q: any = to.query;
            store.dispatch('head/updateTitle', 'おすすめ情報');
            if (typeof q.p !== 'undefined') {
              next();
            } else {
              const query = Object.assign(q, {p: 1});
              next({ path: '/pickup/', query });
            }
          },
        },
        {
          path: ':id',
          component: () => import(/* webpackChunkName: "pickupId" */ '../views/pickup/Id.vue'),
          children: [
            {
              path: '',
              name: 'pickupIdArticle',
              meta: {
                type: 'article',
                category: 'pickup',
              },
              component: () => import(/* webpackChunkName: "pickupIdArticle" */ '../views/pickup/_id/Index.vue'),
            },
          ],
        },
      ],
    },
    {
      path: 'campaign',
      props: {
        title1: 'CAMPAIGN',
        title2: 'キャンペーン',
      },
      component: () => import(/* webpackChunkName: "campaign" */ '../components/layout/LayoutHasPagesTitle.vue'),
      children: [
        {
          path: '',
          component: () => import(/* webpackChunkName: "campaign" */ '../views/campaign/Index.vue'),
          beforeEnter: (to: any, from: any, next: any) => {
            const q: any = to.query;
            store.dispatch('head/updateTitle', 'キャンペーン');
            if (typeof q.p !== 'undefined') {
              next();
            } else {
              const query = Object.assign(q, {p: 1});
              next({ path: '/campaign/', query });
            }
          },
        },
        {
          path: ':id',
          meta: {
            type: 'article',
            category: 'campaign',
          },
          component: () => import(/* webpackChunkName: "campaign" */ '../views/campaign/Id.vue'),
          children: [
            {
              name: 'campaignIndex',
              path: '',
              meta: {
                type: 'article',
                category: 'campaign',
              },
              component: () => import(/* webpackChunkName: "campaign" */ '../views/campaign/_id/index.vue'),
            },
          ],
        },
      ],
    },
    {
      path: 'news',
      props: {
        title1: 'NEWS',
        title2: 'ニュース',
      },
      component: () => import(/* webpackChunkName: "news" */ '../components/layout/LayoutHasPagesTitle.vue'),
      children: [
        {
          path: '',
          component: () => import(/* webpackChunkName: "campaign" */ '../views/news/Index.vue'),
          beforeEnter: (to: any, from: any, next: any) => {
            const q: any = to.query;
            store.dispatch('head/updateTitle', 'ニュース');
            if (typeof q.p !== 'undefined') {
              next();
            } else {
              const query = Object.assign(q, {p: 1});
              next({ path: '/news/', query });
            }
          },
        },
        {
          path: ':id',
          component: () => import(/* webpackChunkName: "news" */ '../views/news/Id.vue'),
          children: [
            {
              name: 'newsIndex',
              path: '',
              meta: {
                type: 'article',
                category: 'info',
              },
              component: () => import(/* webpackChunkName: "campaign" */ '../views/news/_id/Index.vue'),
            },
          ],
        },
      ],
    },
    {
      path: 'specialoffer',
      props: {
        title1: 'SPECIAL OFFER',
        title2: '特別オファー',
      },
      component: () => import(/* webpackChunkName: "specialoffer" */ '../components/layout/LayoutHasPagesTitle.vue'),
      children: [
        {
          path: '',
          component: () => import(/* webpackChunkName: "campaign" */ '../views/specialoffer/Index.vue'),
          beforeEnter: (to: any, from: any, next: any) => {
            const q: any = to.query;
            store.dispatch('head/updateTitle', '特別オファー');
            if (typeof q.p !== 'undefined') {
              next();
            } else {
              const query = Object.assign(q, {p: 1});
              next({ path: '/specialoffer/', query });
            }
          },
        },
        {
          path: ':id',
          component: () => import(/* webpackChunkName: "specialoffer" */ '../views/specialoffer/Id.vue'),
          children: [
            {
              path: '',
              name: 'specialoffer',
              meta: {
                type: 'article',
                category: 'offer',
              },
              component: () => import(/* webpackChunkName: "specialoffer" */ '../views/specialoffer/_id/Index.vue'),
            },
          ],
        },
      ],
    },
    {
      path: 'pages/:slug',
      name: 'pages',
      meta: {
        isPublic: true,
        type: 'article',
      },
      component: () => import(/* webpackChunkName: "pages" */ '../views/Pages.vue'),
    },
    {
      path: 'brand',
      name: 'pages',
      meta: {
        type: 'article',
      },
      component: () => import(/* webpackChunkName: "pages" */ '../views/Brand.vue'),
    },
    // {
    //   path: 'coupon',
    //   redirect: '/coupon/top',
    //   props: {
    //     title1: 'COUPON',
    //     title2: 'SPECIAL CONTENTS',
    //   },
    //   component: () => import(/* webpackChunkName: "coupon" */ '../components/layout/LayoutHasPagesTitle.vue'),
    //   children: [
    //     {
    //       path: 'top',
    //       name: 'coupon_top',
    //       meta: {
    //         type: 'article',
    //       },
    //       component: () => import(/* webpackChunkName: "coupon" */ '../views/CouponIndex.vue'),
    //     },
    //     {
    //       path: ':name',
    //       name: 'coupon_page',
    //       meta: {
    //         type: 'article',
    //         title: 'クーポン',
    //       },
    //       component: () => import(/* webpackChunkName: "coupon" */ '../views/CouponPage.vue'),
    //     },
    //   ],
    // },
    // {
    //   path: 'coupon2021',
    //   redirect: '/coupon2021/top',
    //   props: {
    //     title1: 'COUPON',
    //     title2: 'SPECIAL CONTENTS',
    //   },
    //   component: () => import(/* webpackChunkName: "coupon" */ '../components/layout/LayoutHasPagesTitle.vue'),
    //   children: [
    //     {
    //       path: 'top',
    //       name: 'coupon2021_top',
    //       meta: {
    //         type: 'article',
    //       },
    //       component: () => import(/* webpackChunkName: "coupon" */ '../views/Coupon2021Index.vue'),
    //     },
    //     {
    //       path: ':name',
    //       name: 'coupon2021_page',
    //       meta: {
    //         type: 'article',
    //         title: 'クーポン',
    //       },
    //       component: () => import(/* webpackChunkName: "coupon" */ '../views/Coupon2021Page.vue'),
    //     },
    //   ],
    // },
    // {
    //   path: 'coupon2022',
    //   redirect: '/coupon2022',
    //   props: {
    //     title1: 'COUPON',
    //     title2: 'SPECIAL CONTENTS',
    //   },
    //   component: () => import(/* webpackChunkName: "coupon" */ '../components/layout/LayoutHasPagesTitle.vue'),
    //   children: [
    //     {
    //       path: '',
    //       name: 'coupon2022_page',
    //       meta: {
    //         type: 'article',
    //         title: 'クーポン',
    //       },
    //       component: () => import(/* webpackChunkName: "coupon" */ '../views/Coupon2022Page.vue'),
    //     },
    //   ],
    // },
    {
      path: 'coupon2022_2',
      redirect: '/coupon2022_2/top',
      props: {
        title1: 'COUPON',
        title2: 'SPECIAL CONTENTS',
      },
      component: () => import(/* webpackChunkName: "coupon" */ '../components/layout/LayoutHasPagesTitle.vue'),
      children: [
        {
          path: 'top',
          name: 'coupon2022_2_top',
          meta: {
            type: 'article',
          },
          component: () => import(/* webpackChunkName: "coupon" */ '../views/coupon/CouponIndex2022_2.vue'),
        },
        {
          path: ':name',
          name: 'coupon_page',
          meta: {
            type: 'article',
            title: 'クーポン',
          },
          component: () => import(/* webpackChunkName: "coupon" */ '../views/coupon/CouponPage2022_2.vue'),
        },
      ],
    },
  ],
};
