
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class ButtonLineMini extends Vue {
  get lang(): string {
    return this.$store.getters['auth/lang'];
  }

  get isGroup(): boolean {
    return this.$store.getters['auth/group'];
  }
}
