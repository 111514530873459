import axios, {AxiosResponse, AxiosError} from 'axios';
export const Pages = {
  namespaced: true,
  state: {
    data: null,
    loaded: false,
    statusCode: null,
  },
  getters: {
    loaded: (state: any) => {
      return state.loaded;
    },
    data: (state: any) => {
      return state.data;
    },
    statusCode: (state: any) => {
      return state.statusCode;
    },
    token: (state: any, getters: any, rootState: any, rootGetters: any) => {
      return rootGetters['auth/token'];
    },
    lang: (state: any, getters: any, rootState: any, rootGetters: any) => {
      return rootGetters['auth/lang'];
    },
  },
  mutations: {
    resetData: (state: any) => {
      state.data = null;
    },
    setData: (state: any, data: PageData) => {
      state.data = data;
    },
    setStatusCode: (state: any, data: number) => {
      state.statusCode = data;
    },
    setLoaded: (state: any, bool: boolean) => {
      state.loaded = bool;
    },
  },
  actions: {
    getEntry: async (cxt: any, params: any) => {
      cxt.commit('setLoaded', false);
      const param: any = {
        method: 'get',
        url: `/api/wp-json/31corp/v1/page?name=${encodeURI(params.slug)}&lang=${cxt.getters.lang}`,
        headers: {},
      };
      if (cxt.getters.token) {
        param.headers = { Authorization: 'Bearer ' + cxt.getters.token};
      }
      const res = await axios(param)
      .then( (response: AxiosResponse<ApiPage>) => {
        // cxt.commit('setData', response.data.datas);
        // cxt.dispatch('head/updateTitle', response.data.datas[0].title, {root: true});
        return response;
      })
      .catch( (e: AxiosError) => {
        console.log(e.response);
        return e.response;
      });
      cxt.commit('setLoaded', true);
      return res;
    },
  },
};
