import { Component, Vue, Emit, Watch } from 'vue-property-decorator';
import $ from 'jquery';
import store from '@/store';
import axios, {AxiosResponse, AxiosError} from 'axios';
import ButtonTodayLowestPrice from '@/components/button/ButtonTodayLowestPrice.vue';
import ButtonLine from '@/components/button/ButtonLine.vue';
@Component({
  components: {
    ButtonTodayLowestPrice,
    ButtonLine,
  },
})
export default class NaviFollowSearchnavi extends Vue {
  private name = 'navifollowsearchnavi';
  private isActive = false;
  private isOff = true;
  private hasFbanner = false;

  get isLogedin(): boolean {
    return (this.$store.getters['auth/name']) ? true : false;
  }
  get lang(): string {
    return this.$store.getters['auth/lang'];
  }
  get loaded(): boolean {
    return this.$store.state.loaded;
  }

  @Emit()
  private showNavi() {
    if (this.isActive) {
      this.isActive = false;
    } else {
      this.isActive = true;
    }
  }

  private mounted() {
    if ('IntersectionObserver' in window) {
      const $fSearch = $('.fSearch');
      const target: any = document.querySelector('#header');
      const callback = (entry: any) => {
        if (entry[0].isIntersecting) {
          $('.pageTop').addClass('off');
          if (!this.isActive) {
            this.isOff = true;
          }
        } else {
          $('.pageTop').removeClass('off');
          this.isOff = false;
        }
      };
      const options = {
        threshold: 1.0,
      };
      const observer = new IntersectionObserver(callback, options);
      observer.observe(target);
    }
  }
  @Watch('$route', { immediate: true, deep: true })
  private onChangeRouteStatus(to: any, from: any) {
    this.isActive = false;
    this.isOff = true;

  }
}
