
import { Component, Mixins } from 'vue-property-decorator';
import NaviSerchnaviArea from './';
import NaviSerchnaviAreaLinks from '@/components/navi/NaviSerchnaviAreaLinks/NaviSerchnaviAreaLinks.vue';

@Component({
  components: {
    NaviSerchnaviAreaLinks,
  },
})
export default class NaviSerchnaviAreaJa extends Mixins(NaviSerchnaviArea) {
}
