
import { Component, Mixins } from 'vue-property-decorator';
import store from '@/store';
import DefaultLayout from './DefaultLayout';
import CommonHeader from '@/components/common/CommonHeaderEn.vue';
import CommonFooter from '@/components/common/CommonFooter.vue';
import ModalFavoritesAlert from '@/components/modal/ModalFavoritesAlert/ModalFavoritesAlert.vue';
import NaviFollowSearchnavi from '@/components/navi/NaviFollowSearchnavi/NaviFollowSearchnaviEn.vue';
import ModalSearch from '@/components/modal/ModalSearch/ModalSearch.vue';
import ModalMovie from '@/components/modal/ModalMovie/ModalMovie.vue';
@Component({
  components: {
    CommonHeader,
    CommonFooter,
    ModalFavoritesAlert,
    NaviFollowSearchnavi,
    ModalMovie,
    ModalSearch,
  },
})
export default class DefaultLayoutEn extends Mixins(DefaultLayout) {
}
