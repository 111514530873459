import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class HomeHistoryItem extends Vue {
    private name = 'homehistoryitem';
    private loaded = false;
    @Prop()
    private isRemoveEdit!: boolean;
    @Prop()
    private item: any;
    private mounted() {
        this.loaded = true;
    }
}
