
import { Component, Mixins, Emit, Watch } from 'vue-property-decorator';
import store from '@/store';
import $ from 'jquery';
import magnificPopup from 'magnific-popup';
import axios, {AxiosResponse, AxiosError} from 'axios';
import DefaultLayout from './DefaultLayout';
import CommonHeader from '@/components/common/CommonHeaderJa.vue';
import CommonFooter from '@/components/common/CommonFooter.vue';
import ModalFavoritesAlert from '@/components/modal/ModalFavoritesAlert/ModalFavoritesAlert.vue';
import NaviFollowSearchnavi from '@/components/navi/NaviFollowSearchnavi/NaviFollowSearchnavi.vue';
import ModalTodayLowestPrice from '@/components/modal/ModalTodayLowestPrice/ModalTodayLowestPrice.vue';
import ModalMovie from '@/components/modal/ModalMovie/ModalMovie.vue';
import ModalSearchnavi from '@/components/modal/ModalSearchnavi/ModalSearchnavi.vue';
import ModalSearch from '@/components/modal/ModalSearch/ModalSearch.vue';
import GlobalMenu from './GlobalMenu/GlobalMenu.vue';
import FloatBottom from './FloatBottom/FloatBottom.vue';
import FloatingBanner from '@/components/button/FloatingBanner.vue';
@Component({
  components: {
    CommonHeader,
    CommonFooter,
    ModalFavoritesAlert,
    ModalSearch,
    NaviFollowSearchnavi,
    ModalTodayLowestPrice,
    ModalMovie,
    ModalSearchnavi,
    GlobalMenu,
    FloatBottom,
    FloatingBanner,
  },
})
export default class DefaultLayoutJa extends Mixins(DefaultLayout) {
  get movie(): string {
    return store.getters['auth/movie'];
  }

  @Emit()
  private clickMovieBtn(event: any): void {
    store.commit('movie/open', event.target.href);
  }
}
