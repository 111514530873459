import { Component, Vue, Emit } from 'vue-property-decorator';
// @ is an alias to /src
import axios, {AxiosResponse, AxiosError} from 'axios';
import store from '@/store';
import BoxSnsItem from '@/components/box/BoxSnsItem/BoxSnsItem.vue';
@Component({
  components: {
    BoxSnsItem,
  },
})
export default class HomeSNS extends Vue {
  private name = 'homesns';
  private list: SNS[] = [];
  get lang(): string {
    return store.getters['auth/lang'];
  }
  private mounted() {
    this.getList();
  }


  @Emit()
  private async getList() {
    const api = `/api/wp-json/31corp/v1/sns?lang=${this.lang}`;
    const result = await axios({
      method: 'get',
      url: api,
      headers: { Authorization: 'Bearer ' + this.$store.getters['auth/token']},
    })
    .then( (response: AxiosResponse<SNS[]>) => {
      this.list = response.data;
      return true;
    })
    .catch( (e: AxiosError) => {
      console.log(e);
      return false;
    });
    return result;
  }
}
