export const History = {
  namespaced: true,
  state: {
    hotels: [],
    maxSave: 12,
    key: null,
  },
  getters: {
    hotels: (state: any) => {
      return state.hotels;
    },
    key: (state: any) => {
      return state.key;
    },
    account: (state: any, getters: any, rootState: any, rootGetters: any) => {
      return rootGetters['auth/id'];
    },
  },
  mutations: {
    reset: (state: any, slug: string[]|string ) => {
      state.hotels = [];
      state.key = null;
      // localStorage.removeItem('history');
    },
    add: (state: any, slug: string[]|string ) => {
      let addSlug: string[];
      if (!Array.isArray(slug)) {
        addSlug = [slug];
      } else {
        addSlug = slug;
      }
      const addHotels = addSlug.filter((item) => {
        if (state.hotels.indexOf(item) === -1) {
          return item;
        }
      });
      state.hotels = addHotels.concat(state.hotels);
      if (state.hotels.length > state.maxSave) {
        state.hotels.splice(state.hotels.length - 1, state.hotels.length - state.maxSave);
      }
      localStorage[state.key] = String(state.hotels);
    },
    remove: (state: any, slug: string[]|string) => {
      if (Array.isArray(slug)) {
        const result2 = state.hotels.filter((item: any) => {
          if (state.hotels.indexOf(item) < 0) {
            return item;
          }
        });
        state.hotels = result2;
        localStorage[state.key] = String(result2);
      } else {
        const result = state.hotels.filter((item: any) => {
          if (item !== slug) {
            return item;
          }
        });
        state.hotels = result;
        localStorage[state.key] = String(result);
      }
    },
    setKey: (state: any, account: string) => {
      state.key = 'history' + account;
    },
  },
  actions: {
    init: (cxt: any ) => {
      cxt.commit('setKey', String(cxt.getters.account));
      if (localStorage[cxt.getters.key]) {
        const historyArr = localStorage[cxt.getters.key].split(',');
        cxt.commit('add', historyArr);
      }
    },
  },
};
