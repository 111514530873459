
import { Component, Mixins } from "vue-property-decorator";
import NaviSerchnaviArea from "./";
const Words: Dictionary = {
  btn01: {
    link: "/en/hotel/area/sapporo",
    name: "Sapporo",
  },
  btn02: {
    link: "/en/hotel/area/sendai",
    name: "Sendai",
  },
  btn03: {
    link: "/en/hotel/area/tokyo",
    name: "Tokyo",
  },
  btn04: {
    link: "/en/hotel/area/chiba",
    name: "Chiba",
  },
  btn05: {
    link: "/en/hotel/area/nagoya",
    name: "Nagoya",
  },
  btn06: {
    link: "/en/resort/area/mie",
    name: "Mie",
  },
  btn07: {
    link: "/en/hotel/area/kanazawa",
    name: "Kanazawa",
  },
  btn08: {
    link: "/en/hotel/area/kyoto",
    name: "Kyoto",
  },
  btn09: {
    link: "/en/hotel/area/osaka",
    name: "Osaka",
  },
  btn10: {
    link: "/en/hotel/area/hiroshima-okayama",
    name: "Hiroshima･<br>Okayama",
  },
  btn11: {
    link: "/en/hotel/area/fukuoka-kumamoto",
    name: "Fukuoka･Kumamoto",
  },
  btn12: {
    link: "/en/resort/area/okinawa",
    name: "Okinawa",
  },
  btn13: {
    link: "/en/hotel/area/taiwan",
    name: "Taiwan",
  },
  btn14: {
    link: "/en/hotel/area/shizuoka",
    name: "Shizuoka",
  },
  btn15: {
    link: "/en/hotel/area/yokohama",
    name: "Yokohama",
  },
  btn16: {
    link: "/en/hotel/area/hawaii",
    name: "Hawaii",
  },
};
@Component
export default class NaviSerchnaviAreaLinksEn extends Mixins(
  NaviSerchnaviArea
) {
  private words = Words;
}
