import store from '@/store';
import {endOfNumberPath, validatePathIsEndOfNumber} from './validate';

export const RouterEn = {
  path: '/en',
  component: () => import(/* webpackChunkName: "En" */ '../layout/DefaultLayoutEn.vue'),
  children: [
    {
      path: '',
      name: 'homeEn',
      component: () => import(/* webpackChunkName: "login" */ '../views/en/Home.vue'),
      meta: {
        type: 'website',
      },
    },
    {
      path: 'hotel',
      redirect: 'hotel/1',
      props: {
        title1: 'SEARCH',
        title2: 'FIND THE HOTEL',
      },
      component: () => import(/* webpackChunkName: "search" */ '../components/layout/LayoutHasPagesTitle.vue'),
      children: [
        {
          path: ':p',
          name: 'hotel_all_pages_en',
          meta: {
            type: 'article',
          },
          component: () => import(/* webpackChunkName: "search" */ '../views/en/HotelSearch.vue'),
          beforeEnter: (to: any, from: any, next: any) => {
            validatePathIsEndOfNumber(to.path);
            next();
          },
        },
        {
          path: 'area/:area',
          redirect: 'area/:area/1',
          component: () => import(/* webpackChunkName: "search" */ '../views/en/HotelSearch.vue'),
          children: [
            {
              path: ':p',
              name: 'hotel_area_pages_en',
              meta: {
                type: 'article',
              },
              beforeEnter: (to: any, from: any, next: any) => {
                validatePathIsEndOfNumber(to.path);
                next();
              },
            },
          ],
        },
        {
          path: 'name/:name',
          name: 'hotel_name_en',
          meta: {
            type: 'article',
          },
          component: () => import(/* webpackChunkName: "search" */ '../views/HotelSearch.vue'),
        },
        {
          path: 'name/:name/map',
          name: 'hotel_map_en',
          meta: {
            type: 'article',
            title: 'MAP',
          },
          component: () => import(/* webpackChunkName: "search" */ '../views/en/Map.vue'),
        },
      ],
    },
    {
      path: 'resort',
      redirect: 'resort/1',
      props: {
        title1: 'SEARCH',
        title2: 'FIND THE HOTEL',
      },
      component: () => import(/* webpackChunkName: "search" */ '../components/layout/LayoutHasPagesTitle.vue'),
      children: [
        {
          path: ':p',
          name: 'resort_pages_en',
          meta: {
            type: 'article',
            brand: 'resort',
          },
          component: () => import(/* webpackChunkName: "search" */ '../views/en/ResortSearch.vue'),
          beforeEnter: (to: any, from: any, next: any) => {
            validatePathIsEndOfNumber(to.path);
            next();
          },
        },
        {
          path: 'area/:area',
          redirect: 'area/:area/1',
          component: () => import(/* webpackChunkName: "search" */ '../views/en/ResortSearch.vue'),
          children: [
            {
              path: ':p',
              name: 'resort_area_pages_en',
              meta: {
                type: 'article',
                brand: 'resort',
              },
              beforeEnter: (to: any, from: any, next: any) => {
                validatePathIsEndOfNumber(to.path);
                next();
              },
            },
          ],
        },
        {
          path: 'name/:name',
          name: 'resort_name_en',
          meta: {
            type: 'article',
            brand: 'resort',
          },
          component: () => import(/* webpackChunkName: "search" */ '../views/en/ResortSearch.vue'),
        },
      ],
    },
    {
      path: 'search/:keyword',
      redirect: 'search/:keyword/1',
      props: {
        title1: 'KEYWORD',
        title2: 'FIND THE HOTEL',
      },
      component: () => import(/* webpackChunkName: "search" */ '../components/layout/LayoutHasPagesTitle.vue'),
      children: [
        {
          path: ':p',
          name: 'search_p_en',
          meta: {
            type: 'article',
          },
          component: () => import(/* webpackChunkName: "search" */ '../views/en/SearchKeyword.vue'),
          beforeEnter: (to: any, from: any, next: any) => {
            validatePathIsEndOfNumber(to.path);
            next();
          },
        },
      ],
    },
    {
      path: 'pages/:slug',
      name: 'pagesEn',
      meta: {
        isPublic: true,
        type: 'article',
      },
      component: () => import(/* webpackChunkName: "pages" */ '../views/Pages.vue'),
    },
    {
      path: 'specialoffer',
      props: {
        title1: 'SPECIAL OFFER',
        title2: '',
      },
      component: () => import(/* webpackChunkName: "specialoffer" */ '../components/layout/LayoutHasPagesTitle.vue'),
      children: [
        {
          path: '',
          meta: {
            lang: 'en',
          },
          component: () => import(/* webpackChunkName: "campaign" */ '../views/en/specialoffer/Index.vue'),
          beforeEnter: (to: any, from: any, next: any) => {
            const q: any = to.query;
            store.dispatch('head/updateTitle', 'SPECIAL OFFER');
            if (typeof q.p !== 'undefined') {
              next();
            } else {
              const query = Object.assign(q, {p: 1});
              next({ path: '/en/specialoffer/', query });
            }
          },
        },
        {
          path: ':id',
          component: () => import(/* webpackChunkName: "specialoffer" */ '../views/en/specialoffer/Id.vue'),
          children: [
            {
              path: '',
              name: 'specialoffer-en',
              meta: {
                type: 'article',
                category: 'offer',
                lang: 'en',
              },
              component: () => import(/* webpackChunkName: "specialoffer" */ '../views/en/specialoffer/_id/Index.vue'),
            },
          ],
        },
      ],
    },
    {
      path: 'login',
      name: 'loginEn',
      meta: {
        isPublic: true,
        title: 'LOGIN',
        type: 'website',
      },
      component: () => import(/* webpackChunkName: "login" */ '../views/en/Login.vue'),
      beforeEnter: (to: any, from: any, next: any) => {
        if (store.getters['auth/lastLoggedin']) {
          next({ path: '/en' });
        } else {
          next();
        }
      },
    },
    {
      // 全てにマッチします
      path: '/*',
      beforeEnter: (to: any, from: any, next: any) => {
        next('/en');
      },
    },
  ],
};
