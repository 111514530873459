
import { Component, Vue } from 'vue-property-decorator';
import store from '@/store';
import CommonHeader from '@/components/common/CommonHeaderEn.vue';
import CommonFooter from '@/components/common/CommonFooter.vue';
@Component({
  components: {
    CommonHeader,
    CommonFooter,
  },
})
export default class NoneLayout extends Vue {
  get loaded(): boolean {
    return store.getters.loaded;
  }
}
