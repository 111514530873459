import 'es6-promise/auto';
import Vue from 'vue';
import App from './App.vue';
import DefaultLayout from '@/layout/DefaultLayout.vue';
import DefaultLayoutEn from '@/layout/DefaultLayoutEn.vue';
import NoneLayout from '@/layout/NoneLayout.vue';
import router from './router';
// import VueAnalytics from 'vue-analytics';
import VueGtm from '@gtm-support/vue2-gtm';
import store from './store';
import axios, {AxiosRequestConfig, AxiosProxyConfig, AxiosResponse, AxiosError} from 'axios';
import VueAxios from 'vue-axios';
import $ from 'jquery';
import Platform from 'platform';
axios.interceptors.request.use(function(config) {
  if (typeof config.params === 'undefined') {
    config.params = {};
  }
  if (typeof config.params === 'object') {
    if (typeof URLSearchParams === 'function' && config.params instanceof URLSearchParams) {
      config.params.append('_', String(Date.now()));
    } else {
      config.params._ = Date.now();
    }
  }
  return config;
});
Vue.component('default-layout', DefaultLayout);
Vue.component('default-en-layout', DefaultLayoutEn);
Vue.component('none-layout', NoneLayout);

const platform: any = Platform;
if (platform.name === 'IE') { // IE
  $('body').addClass('ie');
} else if (platform.os.family === 'iOS' || platform.os.family === 'Android') { // mobile device
  $('body').addClass('mobile');
} else if (platform.os.family === 'OS X' && typeof document.ontouchstart !== 'undefined') { // iPad
  $('body').addClass('mobile');
}

import '@/assets/style.scss';

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);

// Vue.use(VueGtm, {
//   id: 'GTM-5GG4QQB7',
// });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
