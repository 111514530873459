
import { Component, Vue, Mixins } from 'vue-property-decorator';
import FormKeywordRecommend from '@/components/form/FormKeywordRecommend/';
const Words: Dictionary = {
  placeholder: 'ホテル名・地名から探す',
  history: '最近検索したキーワードから探す',
  keyword: '人気のキーワードから探す',
};
@Component
export default class FormKeyword extends Mixins(FormKeywordRecommend) {
  private words = Words;
}
