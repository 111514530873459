
import { Component, Prop, Emit, Watch, Vue } from 'vue-property-decorator';
import VueRouter from 'vue-router';

@Component
export default class GlobalMenu extends Vue {
  private name = 'GlobalMenu';
  @Prop()
  private data!: any;
}
