import { Component, Vue, Emit } from 'vue-property-decorator';
import $ from 'jquery';
// @ is an alias to /src
import store from '@/store';
@Component
export default class FormKeyword extends Vue {
  private name = 'formkeyword';
  private keyword: string|null = null;
  get recommend(): string[] {
    return this.$store.getters['search/recommend'];
  }
  get history(): string[] {
    return this.$store.getters['search/history'];
  }
  get lang(): string {
    return this.$store.getters['auth/lang'];
  }
  @Emit()
  private onSubmit(): void {
    if (this.keyword && this.keyword.length > 0) {
      let path = `/search/${this.keyword}`;
      if (this.lang === 'en') {
        path = '/en' + path;
      }
      this.$router.push(path);
      this.resetKeyword();
      this.closeModal();
    }
  }
  @Emit('update-keyword')
  private updateKeyword(word: string|null) {
    if (word) {
      this.keyword = word;
    }
  }
  @Emit()
  private setValue(word: string): void {
    this.keyword = word;
    this.onSubmit();
  }
  @Emit('reset-keyword')
  private resetKeyword() {
    this.keyword = null;
  }
  @Emit()
  private onFocus(e: any): void {
    const overlay: any = this.$refs.overlay;
    const recommend: any = this.$refs.recommend;
    if (!overlay || !recommend) {
      return;
    }
    const $overlay: any = $(overlay);
    $overlay.stop().fadeIn();
    const $recommend: any = $(recommend);
    $recommend.stop().slideDown();
  }
  @Emit()
  private closeModal(): void {
    const overlay: any = this.$refs.overlay;
    const recommend: any = this.$refs.recommend;
    const $overlay: any = $(overlay);
    $overlay.stop().fadeOut();
    const $recommend: any = $(recommend);
    $recommend.stop().slideUp();
  }
  @Emit()
  private closeModalSearchNavi(): void {
    store.commit('searchmodal/close', 'ModalSearchnavi');
  }
  private mounted() {
    if (store.getters['auth/token']) {
      store.dispatch('search/getRecommend');
    }
  }
}
