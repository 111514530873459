
import { Component, Mixins, Watch } from 'vue-property-decorator';
import NaviFollowSearchnavi from '@/components/navi/NaviFollowSearchnavi';
import FloatingBanner from '@/components/button/FloatingBanner.vue';
import NaviSerchnavi from '@/components/navi/NaviSerchnavi/NaviSerchnavi.vue';
const Words: Dictionary = {
  btSearchBox: 'ホテルを検索する',
  btClose: '閉じる',
};
@Component({
  components: {
    NaviSerchnavi,
    FloatingBanner,
  },
})
export default class NaviFollowSearchnaviJa extends Mixins(NaviFollowSearchnavi) {
  private words = Words;
  private showLinebnr = false;

  get isGroup(): boolean {
    return this.$store.getters['auth/group'];
  }

  @Watch('$route', { immediate: true, deep: true })
  private onUrlChange(newVal: any) {
    if (/^\/pages\/line/.test(newVal.path)) {
      this.showLinebnr = false;
    } else {
      this.showLinebnr = true;
    }
  }
}
