
import { Vue, Component, Emit, Watch } from 'vue-property-decorator';
import ButtonTodayLowestPrice from '@/components/button/ButtonTodayLowestPrice.vue';
import NaviSerchnavi from '@/components/navi/NaviSerchnavi/NaviSerchnavi.vue';
import store from '@/store';
// import $ from 'jquery';
// import magnificPopup from 'magnific-popup';
// const magnificpopup = magnificPopup;

const Words: Dictionary = {
  btSearchBox: 'ホテルを検索する',
  btClose: '閉じる',
};

@Component({
  components: {
    ButtonTodayLowestPrice,
    NaviSerchnavi,
  },
})
export default class ModalSearchnavi extends Vue {
  private words = Words;
  private showLinebnr = false;

  get isGroup(): boolean {
    return this.$store.getters['auth/group'];
  }

  // @Watch('$route', { immediate: true, deep: true })
  // private onUrlChange(newVal: any) {
  //   this.close();
  // }

  @Emit()
  private close() {
    // $.magnificPopup.close();
    store.dispatch('searchmodal/close');
  }

  // @Emit()
  // private open() {
  //   const $modalSearch: any = $('#modalSearch');
  //   if (typeof $modalSearch !== 'undefined') {
  //     $.magnificPopup.open({
  //       items: {
  //         src: '#modalSearch',
  //         type: 'inline',
  //       },
  //       callbacks: {
  //         close() {
  //           store.commit('today/close');
  //         },
  //       },
  //     });
  //   }
  // }
}
