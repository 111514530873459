import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class HomeFavoritelistItem extends Vue {
    private name = 'homefavoritelistitem';
    private loaded = false;
    @Prop()
    private isRemoveEdit!: boolean;
    @Prop()
    private item!: HotelDetail;
    private mounted() {
        this.loaded = true;
    }
}
