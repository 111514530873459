const data: HomeSlider[] = [
  {
    caption: "三井ガーデンホテル神宮外苑の杜プレミア",
    image: require("@/assets/assets/imgs/HomeSlider/01/MGH-Gaien-4.jpg")
  },
  {
    caption: "三井ガーデンホテル神宮外苑の杜プレミア",
    image: require("@/assets/assets/imgs/HomeSlider/01/MGH-Gaien-10.jpg")
  },
  {
    caption: "三井ガーデンホテル神宮外苑の杜プレミア",
    image: require("@/assets/assets/imgs/HomeSlider/01/MGH-Gaien-11.jpg")
  },
  {
    caption: "三井ガーデンホテル六本木プレミア",
    image: require("@/assets/assets/imgs/HomeSlider/01/MGH-Roppongi-5.jpg")
  },
  {
    caption: "三井ガーデンホテル福岡祇園",
    image: require("@/assets/assets/imgs/HomeSlider/02/MGH-Gion-2.jpg")
  },
  {
    caption: "三井ガーデンホテル福岡中洲",
    image: require("@/assets/assets/imgs/HomeSlider/02/MGH-Nakasu-16.jpg")
  },
  {
    caption: "三井ガーデンホテル六本木プレミア",
    image: require("@/assets/assets/imgs/HomeSlider/02/MGH-Roppongi-2.jpg")
  },
  {
    caption: "三井ガーデンホテル豊洲プレミア",
    image: require("@/assets/assets/imgs/HomeSlider/02/MGH-Toyosu-2.jpg")
  },
  {
    caption: "三井ガーデンホテル神宮外苑の杜プレミア",
    image: require("@/assets/assets/imgs/HomeSlider/03/MGH-Gaien-3.jpg")
  },
  {
    caption: "三井ガーデンホテル日本橋プレミア",
    image: require("@/assets/assets/imgs/HomeSlider/03/MGH-Nihonbashi-11.jpg")
  },
  {
    caption: "三井ガーデンホテル六本木プレミア",
    image: require("@/assets/assets/imgs/HomeSlider/03/MGH-Roppongi-11.jpg")
  },
  {
    caption: "三井ガーデンホテル豊洲プレミア",
    image: require("@/assets/assets/imgs/HomeSlider/03/MGH-Toyosu-12.jpg")
  },
  {
    caption: "三井ガーデンホテル神宮外苑の杜プレミア",
    image: require("@/assets/assets/imgs/HomeSlider/04/MGH-Gaien-11.jpg")
  },
  {
    caption: "三井ガーデンホテル福岡中洲",
    image: require("@/assets/assets/imgs/HomeSlider/04/MGH-Nakasu-7.jpg")
  },
  {
    caption: "三井ガーデンホテル日本橋プレミア",
    image: require("@/assets/assets/imgs/HomeSlider/04/MGH-Nihonbashi-6.jpg")
  },
  {
    caption: "三井ガーデンホテル豊洲プレミア",
    image: require("@/assets/assets/imgs/HomeSlider/04/MGH-Toyosu-8.jpg")
  },
  {
    caption: "三井ガーデンホテル神宮外苑の杜プレミア",
    image: require("@/assets/assets/imgs/HomeSlider/05/MGH-Gaien-6.jpg")
  },
  {
    caption: "三井ガーデンホテル福岡中洲",
    image: require("@/assets/assets/imgs/HomeSlider/05/MGH-Nakasu-2.jpg")
  },
  {
    caption: "三井ガーデンホテル日本橋プレミア",
    image: require("@/assets/assets/imgs/HomeSlider/05/MGH-Nihonbashi-1.jpg")
  },
  {
    caption: "三井ガーデンホテル豊洲プレミア",
    image: require("@/assets/assets/imgs/HomeSlider/05/MGH-Toyosu-5.jpg")
  },
  {
    caption: "三井ガーデンホテル福岡祇園",
    image: require("@/assets/assets/imgs/HomeSlider/07/MGH-Gion-6.jpg")
  },
  {
    caption: "三井ガーデンホテル福岡中洲",
    image: require("@/assets/assets/imgs/HomeSlider/07/MGH-Nakasu-8.jpg")
  },
  {
    caption: "三井ガーデンホテル豊洲プレミア",
    image: require("@/assets/assets/imgs/HomeSlider/07/MGH-Toyosu-7.jpg")
  },
  {
    caption: "三井ガーデンホテル豊洲プレミア",
    image: require("@/assets/assets/imgs/HomeSlider/07/MGH-Toyosu-8.jpg")
  },
  {
    caption: "三井ガーデンホテル神宮外苑の杜プレミア",
    image: require("@/assets/assets/imgs/HomeSlider/08/MGH-Gaien-9.jpg")
  },
  {
    caption: "三井ガーデンホテル福岡祇園",
    image: require("@/assets/assets/imgs/HomeSlider/08/MGH-Gion-11.jpg")
  },
  {
    caption: "三井ガーデンホテル福岡祇園",
    image: require("@/assets/assets/imgs/HomeSlider/08/MGH-Gion-15.jpg")
  },
  {
    caption: "三井ガーデンホテル六本木プレミア",
    image: require("@/assets/assets/imgs/HomeSlider/08/MGH-Roppongi-12.jpg")
  },
  {
    caption: "鳥羽国際ホテル",
    image: require("@/assets/assets/imgs/HomeSlider/09/tobahotel.jpg")
  },
  {
    caption: "三井ガーデンホテル横浜みなとみらいプレミア",
    image: require("@/assets/assets/imgs/HomeSlider/09/MGH-Yokohama-1.jpg")
  },
  {
    caption: "三井ガーデンホテル横浜みなとみらいプレミア",
    image: require("@/assets/assets/imgs/HomeSlider/09/MGH-Yokohama-2.jpg")
  },
  {
    caption: "三井ガーデンホテル横浜みなとみらいプレミア",
    image: require("@/assets/assets/imgs/HomeSlider/09/MGH-Yokohama-3.jpg")
  },
];
export default data;
