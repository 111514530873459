
import { Component, Mixins, Prop } from 'vue-property-decorator';
import BoxHotel from '@/mixins/BoxHotel';
const Words: Dictionary = {
  address: '住所',
  access: '交通',
  btOfficialSite: '公式ホームページ',
  btReserve: '予約する（法人優待）',
};
@Component
export default class ModalTodayLowestPriceResultHotel extends Mixins(BoxHotel) {
  private name = 'modaltodaylowestpriceresulthotel';
  private words = Words;
  @Prop()
  private schedule?: ReservableListSchedule;
}
