
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class HomeCampaignlistItem extends Vue {
  private name = 'homecampaignlistitem';
  private loaded = false;
  @Prop()
  private item!: PostData;
  private mounted() {
    this.loaded = true;
  }
}
