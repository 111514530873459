import { Component, Vue, Prop } from 'vue-property-decorator';
import store from '@/store';
@Component
export default class NaviSerchnavi extends Vue {
  private　name = 'NaviSerchnavi';
  get lang(): string {
    return this.$store.getters['auth/lang'];
  }
  get pdfLink() {
    return process.env.VUE_APP_SEARCH_PDF_URL;
  }
  get isGroup(): boolean {
    return this.$store.getters['auth/group'];
  }
  @Prop({default: null})
  private wrapClass!: string;
}
