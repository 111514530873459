
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class HomeNewslistItem extends Vue {
    private name = 'homenewslistitem';
    private loaded = false;
    @Prop()
    private item!: PostData;
    private mounted() {
        this.loaded = true;
    }
}
