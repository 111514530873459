import axios, {AxiosResponse, AxiosError} from 'axios';
import store from '.';
export const Auth = {
  namespaced: true,
  state: {
    token: null,
    account: null,
    lang!: null,
    nameJa: null,
    nameEn: null,
    group: null,
    id: null,
    movie: null,
    lastLoggedin: null,
    visited: false,
  },
  getters: {
    token: (state: any) => {
      return state.token;
    },
    lang: (state: any) => {
      return state.lang;
    },
    name: (state: any) => {
      return state.nameJa;
    },
    nameEn: (state: any) => {
      return state.nameEn;
    },
    account: (state: any) => {
      return state.account;
    },
    group: (state: any) => {
      return state.group;
    },
    id: (state: any) => {
      return state.id;
    },
    movie: (state: any) => {
      return state.movie;
    },
    lastLoggedin: (state: any) => {
      return state.lastLoggedin;
    },
    visited: (state: any) => {
      return state.visited;
    },
  },
  mutations: {
    login: (state: any, token: any) => {
      state.token = token;
    },
    setToken: (state: any, token: any) => {
      state.token = token;
    },
    updateId: (state: any, id: string) => {
      state.id = `${id}`;
    },
    updateMovie: (state: any, movie: string) => {
      state.movie = `${movie}`;
    },
    removeLastLoggedin: (state: any, str: string) => {
      state.lastLoggedin = null;
      localStorage.removeItem('lastLoggedin');
    },
    updateLastLoggedin: (state: any, str: string) => {
      state.lastLoggedin = Number(localStorage.lastLoggedin);
    },
    setLastLoggedin: (state: any) => {
      const date = new Date();
      state.lastLoggedin = Number(date.getTime());
      localStorage.lastLoggedin = String(date.getTime());
    },
    logout: (state: any) => {
      state.token = null;
      state.nameJa = null;
      state.nameEn = null;
      state.account = null;
      state.group = null;
      state.movie = null;
    },
    updateLang: (state: any, lang: string) => {
      state.lang = lang;
    },
    updateName: (state: any, name: any) => {
      state.nameJa = name.ja;
      state.nameEn = name.en;
    },
    updateAccount: (state: any, str: string) => {
      state.account = str;
    },
    updateGroup: (state: any, group: string) => {
      state.group = group;
    },
    updateVisited: (state: any, bool: any) => {
      state.visited = bool;
    },
  },
  actions: {
    login: async (cxt: any, param: any) => {
      const url = `/api/wp-json/jwt-auth/${process.env.VUE_APP_VERSION}token?lang=${cxt.getters.lang}`;
      const res = await axios.post(url, param)
      .then( (response: AxiosResponse<ApiLogin>) => {
        if (!response.data.redirectUrl) {
          cxt.commit('login', response.data.token);
          cxt.commit('setLastLoggedin');
          cxt.commit('updateName', {ja: response.data.username_ja, en: response.data.username_en});
          cxt.commit('updateAccount', response.data.account);
          cxt.commit('updateGroup', response.data.group);
          cxt.commit('updateId', response.data.unique_user_no);
          cxt.commit('updateMovie', response.data.movie);
          cxt.dispatch('search/historyInit', null, {root: true});
          cxt.dispatch('favorites/init', null, {root: true});
          cxt.dispatch('history/init', null, {root: true});
        }
        return response;
      })
      .catch( (error: AxiosError) => {
        return false;
      });
      return res;
    },
    logout: async (cxt: any, param: any) => {
      const api = '/api/wp-json/jwt-auth/v1/logout';
      const res = await axios.get(
        api,
        { headers: { Authorization: 'Bearer ' + cxt.getters.token} },
      )
      .then( (response: AxiosResponse<ApiLogout>) => {
        cxt.commit('logout');
        cxt.commit('removeLastLoggedin');
        cxt.commit('search/logout', null, {root: true});
        cxt.commit('favorites/reset', null, {root: true});
        cxt.commit('history/reset', null, {root: true});
        return true;
      })
      .catch( (error: AxiosError) => {
        return false;
      });
      return res;
    },
    isLoggedin: async (cxt: any, param: any) => {
      const url = `/api/wp-json/31corp/${process.env.VUE_APP_VERSION}user`;

      if (cxt.getters.visited) {
        if (cxt.getters.token) {
          return true;
        } else {
          return false;
        }
      }

      cxt.commit('updateVisited', true);

      let params: any = {}

      const token = await axios.get( `/api/wp-json/31corp/${process.env.VUE_APP_VERSION}user` )

      if (!token.data.token) {
        return false;
      }

      params = {}
      if (token.data.token) {
        params.headers = {
          Authorization: `Bearer ${token.data.token}`
        }
      }
      const res = await axios.get( url, params )
      .then( (response: AxiosResponse<ApiLogin>) => {
        if (response.data.redirectUrl) {
          return false;
        }
        cxt.commit('setToken', token.data.token);
        cxt.commit('updateLastLoggedin');
        cxt.commit('updateName', {ja: response.data.username_ja, en: response.data.username_en});
        cxt.commit('updateAccount', response.data.account);
        cxt.commit('updateGroup', response.data.group);
        cxt.commit('updateId', response.data.unique_user_no);
        cxt.commit('updateMovie', response.data.movie);
        cxt.dispatch('search/historyInit', null, {root: true});
        cxt.dispatch('favorites/init', null, {root: true});
        cxt.dispatch('history/init', null, {root: true});
        return response;
      })
      .catch( (error: AxiosError) => {
        cxt.commit('logout');
        return false;
      });
      if (cxt.getters.token) {
        return true;
      } else {
        return false;
      }
    },
    autoLogin: async (cxt: any, param: any) => {
      const url = `/api/wp-json/31corp/${process.env.VUE_APP_VERSION}user`;

      if (cxt.getters.visited) {
        if (cxt.getters.token) {
          return true;
        } else {
          return false;
        }
      }

      cxt.commit('updateVisited', true);

      let params: any = {}

      if (typeof param.line_id_token !== 'undefined') {
        params = {
          line_id_token: param.line_id_token
        }
      }

      const token = await axios.post( `/api/wp-json/31corp/${process.env.VUE_APP_VERSION}user`, params )

      if (!token.data.token) {
        return false;
      }

      params = {}
      if (typeof token.data.account !== 'undefined' && typeof token.data.password !== 'undefined') {
        params = {
          username: token.data.account,
          password: token.data.password,
        };
      }
      const res = await cxt.dispatch('login', params)
      .then( (response: AxiosResponse<ApiLogin>) => {
        return true;
      })
      .catch( (error: AxiosError) => {
        cxt.commit('logout');
        return false;
      });
    },
  },
};
