
import { Component, Prop, Emit, Watch, Vue } from 'vue-property-decorator';
import SiteLogo from '@/components/SiteLogo.vue';

@Component({
  components: {
    SiteLogo,
  },
})
export default class FloatBottom extends Vue {
  private name = 'floatBottom';
  private spMenuFlg: boolean = false;
  @Prop()
  private data!: any;

  @Emit()
  private openSpMenu(): void {
    this.spMenuFlg = true;
  }

  @Emit()
  private closeSpMenu(): void {
    this.spMenuFlg = false;
  }

  @Watch('$route', { immediate: true, deep: true })
  private onUrlChange(newVal: any) {
    this.closeSpMenu();
  }
}
