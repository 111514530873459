
import { Component, Mixins } from 'vue-property-decorator';
import NaviSerchnaviKeyword from '@/components/navi/NaviSerchnaviKeyword';
import FormKeywordRecommend from '@/components/form/FormKeywordRecommend/FormKeywordRecommendEn.vue';
const Words: Dictionary = {
  label: 'Enter keyword',
};
@Component({
  components: {
    FormKeywordRecommend,
  },
})
export default class NaviSerchnaviKeywordEn extends Mixins(NaviSerchnaviKeyword) {
  private words = Words;
}
