
import { Component, Mixins } from 'vue-property-decorator';
import NaviSerchnavi from '@/components/navi/NaviSerchnavi/';
import NaviSerchnaviArea from '@/components/navi/NaviSerchnaviArea/NaviSerchnaviAreaEn.vue';
import NaviSerchnaviKeyword from '@/components/navi/NaviSerchnaviKeyword/NaviSerchnaviKeywordEn.vue';
import NaviSerchnaviLink from '@/components/navi/NaviSerchnaviLink/NaviSerchnaviLinkEn.vue';
const Words: Dictionary = {
  label: 'Please select your destination area',
};
@Component({
  components: {
    NaviSerchnaviArea,
    NaviSerchnaviKeyword,
    NaviSerchnaviLink,
  },
})
export default class NaviSerchnaviJa extends Mixins(NaviSerchnavi) {
  private words = Words;
}
