
import { Component, Vue, Emit, Watch } from 'vue-property-decorator';
import ModalTodayLowestPriceResult from './ModalTodayLowestPriceResult.vue';
import ModalTodayLowestPriceForm from './ModalTodayLowestPriceForm.vue';
import store from '@/store';
import $ from 'jquery';
import axios, {AxiosResponse, AxiosError} from 'axios';
import magnificPopup from 'magnific-popup';
const magnificpopup = magnificPopup;
@Component({
  components: {
    ModalTodayLowestPriceForm,
    ModalTodayLowestPriceResult,
  },
})
export default class ModalTodayLowestPrice extends Vue {
  private name = 'modaltodaylowestprice';
  private today: Date = new Date();
  private optionsMaxPeople: number = 6;
  get loaded(): OptionArea {
    return this.$store.getters['today/loaded'];
  }
  get optionsArea(): OptionArea {
    return this.$store.getters['today/options'];
  }
  get valuePeople(): number {
    return this.$store.getters['today/people'];
  }
  get hotelData(): ReservableList[] {
    return this.$store.getters['today/hotels'];
  }
  get valueArea(): string {
    return this.$store.getters['today/area'];
  }
  set valueArea(value) {
    this.$store.commit('today/area', value);
  }
  get valueStay(): number {
    return this.$store.getters['today/stay'];
  }
  set valueStay(value) {
    this.$store.commit('today/stay', value);
  }
  get modalState(): boolean {
    return this.$store.getters['today/open'];
  }
  get lang(): string {
    return this.$store.getters['auth/lang'];
  }
  get area(): string {
    if (this.lang === 'ja') {
      return this.optionsArea[this.valueArea].text.ja;
    } else {
      return this.optionsArea[this.valueArea].text.en;
    }
  }
  get headerText(): string {
    if (this.hotelData.length === 0) {
      return `今夜の宿泊予約(最安値価格表示あり)`;
    } else {
      return `今夜、${this.valuePeople}名様が${this.valueStay}泊でご宿泊可能なホテルを表示`;
    }
  }
  @Watch('modalState')
  private onModalStateChanged(newState: boolean) {
    if (newState) {
      this.open();
    } else {
      this.close();
    }
  }
  @Emit()
  private resetHotelData() {
    this.$store.commit('today/hotels', []);
  }
// @Emit()
// private open() {
//   this.resetHotelData();
//   this.openModal();
// }
// @Emit()
// private openModal() {
//   const $todaylowestprice: any = $('#todaylowestprice');
//   if (typeof $todaylowestprice !== 'undefined') {
//     $.magnificPopup.open({
//       items: {
//         src: '#todaylowestprice',
//         type: 'inline',
//       },
//       callbacks: {
//         close() {
//           store.commit('today/close');
//         },
//       },
//     });
//   }
// }
  @Emit()
  private close() {
    // $.magnificPopup.close();
    store.dispatch('searchmodal/close');
  }
  @Emit()
  private async init(): void {
    const $query: any = this.$route.query;
    if (typeof $query === 'undefined' || typeof $query.today_calender_area === 'undefined') {
      store.commit('today/loaded', true);
      return;
    }
    if (typeof $query !== 'undefined' && typeof $query.today_calender_area !== 'undefined') {
      store.commit('today/area', $query.today_calender_area);
    }
    if (typeof $query !== 'undefined' && typeof $query.today_calender_people !== 'undefined') {
      store.commit('today/people', Number($query.today_calender_people));
    }
    store.commit('today/loaded', false);
    await this.$store.dispatch(
      'today/getReserveList',
      {
        startDate: new Date(),
        area: store.getters['today/area'],
        people: store.getters['today/people'],
        stay: store.getters['today/stay'],
      },
    );
    store.commit('today/loaded', true);
    // if (typeof $query !== 'undefined' && typeof $query.today_calender_area !== 'undefined') {
    //   this.openModal();
    // }
    return;
  }
  private mounted() {
    this.init();
  }
}
