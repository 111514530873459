
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
// @ is an alias to /src
import ButtonMovie from '@/components/button/ButtonMovie.vue';

interface linkData {
  facebook: string;
  instagram: string;
  movie: string;
  youtube: string;
}

interface BoxSnsItemData {
  name: string;
  image: string;
  link: linkData;
  summary: string;
  url: string;
}

@Component({
  components: {
    ButtonMovie
  }
})
export default class BoxSnsItem extends Vue {
  private name = 'boxpickup';
  @Prop({ default: null })
  private item!: BoxSnsItemData;
  @Emit()
  private movieIcon(url: string) {
    if (/(https?:)?\/\/vimeo\.com\//.test(url)) {
      return require('@/assets/assets/imgs/ico-vimeo.svg');
    } else {
      return require('@/assets/assets/imgs/ico-youtube.svg');
    }
  }
  @Emit()
  private movieAlt(url: string) {
    if (/(https?:)?\/\/vimeo\.com\//.test(url)) {
      return 'Vimeo';
    } else {
      return 'Youtube';
    }
  }
}
