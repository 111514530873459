import { Component, Watch, Emit, Prop, Vue } from 'vue-property-decorator';
import store from '@/store';
// @ is an alias to /src

@Component
export default class ButtonFavoriteHeart extends Vue {
  private name = 'buttonfavoriteheart';
  private isActive: boolean = false;
  @Prop()
  private slug!: string;
  @Emit()
  private addFavorite() {
    store.commit('favorites/add', this.slug);
  }
  @Emit()
  private remove() {
    store.commit('favorites/remove', this.slug);
  }
  @Emit()
  private changeFavorite() {
    const myFavorites = this.$store.getters['favorites/hotels'];
    if (myFavorites.indexOf(this.slug) === -1) {
      this.addFavorite();
    } else {
      this.remove();
    }
  }
  @Watch('$store.state.favorites', { immediate: true, deep: true })
  private onChangeFavoritesStatus() {
    const myFavorites = this.$store.getters['favorites/hotels'];
    if (myFavorites.indexOf(this.slug) === -1) {
      this.isActive = false;
    } else {
      this.isActive = true;
    }
  }
  private mounted() {
    const myFavorites = this.$store.getters['favorites/hotels'];
    if (myFavorites.indexOf(this.slug) === -1) {
      this.isActive = false;
    } else {
      this.isActive = true;
    }
  }
}
