
import { Component, Vue, Emit } from 'vue-property-decorator';
import axios, {AxiosResponse, AxiosError} from 'axios';
import magnificPopup from 'magnific-popup';
const magnificpopup = magnificPopup;
@Component
export default class ModalTodayLowestPriceForm extends Vue {
  private name = 'modaltodaylowestpriceform';
  private today: Date = new Date();
  private optionsMaxPeople: number = 6;
  private hotelData: ReservableList[] = [];
  get optionsArea(): OptionArea {
    return this.$store.getters['today/options'];
  }
  get valuePeople(): number {
    return this.$store.getters['today/people'];
  }
  set valuePeople(value) {
    this.$store.commit('today/people', value);
  }
  get valueArea(): string {
    return this.$store.getters['today/area'];
  }
  set valueArea(value) {
    this.$store.commit('today/area', value);
  }
  get valueStay(): number {
    return this.$store.getters['today/stay'];
  }
  set valueStay(value) {
    this.$store.commit('today/stay', value);
  }
  get todayY(): number {
    return this.today.getFullYear();
  }
  get todayM(): number {
    return this.today.getMonth() + 1;
  }
  get todayD(): number {
    return this.today.getDate();
  }
  get todayW(): string {
    const resD = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());
    return this.wordsDay[resD.getDay()];
  }
  get lang(): string {
    return this.$store.getters['auth/lang'];
  }
  get wordsDay(): string[] {
    if (this.lang === 'ja') {
      return ['日', '月', '火', '水', '木', '金', '土'];
    } else {
      return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    }
  }
  @Emit()
  private clickButton(): void {
    this.getReserveList();
  }
  @Emit()
  private async getReserveList() {
    await this.$store.dispatch(
      'today/getReserveList',
      {
        startDate: new Date(),
        area: this.valueArea,
        people: this.valuePeople,
        stay: this.valueStay,
      },
    );
  }
  @Emit()
  private setHotelData(hotels: any) {
    this.$store.commit('today/hotels', hotels);
  }
  @Emit()
  private resetHotelData() {
    this.hotelData = [];
  }
  private mounted() {
    window.gtag('event', 'page_view', {
      page_location: '/today',
      page_path: '/today',
      page_title: '今夜の宿泊予約(最安値価格表示あり)',
    });
  }
}
