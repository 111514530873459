export const Searchmodal = {
  namespaced: true,
  state: {
    isOpen: false,
    component: '',
  },
  getters: {
    open: (state: any) => {
      return state.isOpen;
    },
    component: (state: any) => {
      return state.component;
    },
  },
  mutations: {
    open: (state: any, component: string) => {
      state.component = component;
      state.isOpen = true;
    },
    close: (state: any) => {
      state.isOpen = false;
    },
  },
  actions: {
    close: (cxt: any, params: any) => {
      cxt.commit('today/hotels', [], {root: true});
      cxt.commit('close');
    },
  }
};
