
import { Component, Mixins } from 'vue-property-decorator';
import HomeFavoritelistItem from './';
import BoxListItem from '@/components/box/BoxListItem/BoxListItem.vue';
import ButtonFavoriteRemove from '@/components/button/ButtonFavoriteRemove.vue';
@Component({
  components: {
    BoxListItem,
    ButtonFavoriteRemove,
  },
})
export default class HomeFavoritelistItemJa extends Mixins(HomeFavoritelistItem) {
}
