export const Head = {
  namespaced: true,
  state: {
    title: null,
    description: process.env.VUE_APP_DISC,
    keywords: process.env.VUE_APP_KEYWORD,
    og_site_name: null,
    og_title: null,
    og_type: null,
    og_description: null,
    og_image: null,
    og_url: null,
    og_locale: null,
  },
  getters: {
    title: (state: any) => {
      return state.title;
    },
    description: (state: any) => {
      return state.description;
    },
    keywords: (state: any) => {
      return state.keywords;
    },
    og_site_name: (state: any) => {
      return state.og_site_name;
    },
    og_title: (state: any) => {
      return state.og_title;
    },
    og_type: (state: any) => {
      return state.og_type;
    },
    og_description: (state: any) => {
      return state.og_description;
    },
    og_image: (state: any) => {
      return state.og_image;
    },
    og_url: (state: any) => {
      return state.og_url;
    },
    og_locale: (state: any) => {
      return state.og_locale;
    },
    lang: (state: any, getters: any, rootState: any, rootGetters: any) => {
      return rootGetters['auth/lang'];
    },
  },
  mutations: {
    updateTitle: (state: any, text: string) => {
      state.title = text;
    },
    updateDescription: (state: any, text: string) => {
      state.description = text;
    },
    updateKeywords: (state: any, text: string) => {
      state.keywords = text;
    },
    updateOgSiteName: (state: any, text: string) => {
      state.og_site_name = text;
    },
    updateOgTitle: (state: any, text: string) => {
      state.og_title = text;
    },
    updateOgType: (state: any, text: string) => {
      state.og_type = text;
    },
    updateOgDescription: (state: any, text: string) => {
      state.og_description = text;
    },
    // updateOgImage: (state: any, text: string) => {
    //   state.og_image = text;
    // },
    updateOgUrl: (state: any, text: string) => {
      state.og_url = text;
    },
    updateOgLocale: (state: any, text: string) => {
      state.og_locale = text;
    },
  },
  actions: {
    updateTitle: (cxt: any, docTitle: string|null ) => {
      let siteName = process.env.VUE_APP_SITE_NAME_EN;
      if (cxt.getters.lang === 'ja') {
        siteName = process.env.VUE_APP_SITE_NAME;
      }
      let title = `${docTitle} | ${siteName}`;
      if (!docTitle) {
        title = siteName;
      }
      cxt.commit('updateTitle', title);
      cxt.commit('updateOgTitle', title);
    },
    updateDescription: (cxt: any, docDesc: string|null) => {
      let description = process.env.VUE_APP_DISC_EN;
      if (cxt.getters.lang === 'ja') {
        description = process.env.VUE_APP_DISC;
      }
      cxt.commit('updateDescription', description);
      cxt.commit('updateOgDescription', description);
    },
    updateKeywords: (cxt: any) => {
      let keywords = '';
      if (cxt.getters.lang === 'ja') {
        keywords = process.env.VUE_APP_KEYWORD;
      }
      cxt.commit('updateKeywords', keywords);
    },
    updateOgSiteName: (cxt: any) => {
      let sitename = process.env.VUE_APP_SITE_NAME_EN;
      if (cxt.getters.lang === 'ja') {
        sitename = process.env.VUE_APP_SITE_NAME;
      }
      cxt.commit('updateOgSiteName', sitename);
    },
    updateOgType: (cxt: any, ogType: string|null) => {
      let type = ogType;
      if (!ogType) {
        type = 'article';
      }
      cxt.commit('updateOgType', type);
    },
    // updateOgImage: (cxt: any, ogImagesPath: string|null) => {
    //   let path = process.env.VUE_APP_SITE_URL + ogImagesPath;
    //   if (!ogImagesPath) {
    //     path = process.env.VUE_APP_SITE_URL + '/imgs/og.jpg';
    //   }
    //   cxt.commit('updateOgImage', path);
    // },
    updateOgUrl: (cxt: any, siteurl: string ) => {
      cxt.commit('updateOgUrl', process.env.VUE_APP_SITE_URL + siteurl);
    },
    updateOgLocale: (cxt: any ) => {
      if (cxt.getters.lang === 'ja') {
        cxt.commit('updateOgLocale', 'ja_JP');
      } else {
        cxt.commit('updateOgLocale', 'es_ES');
      }
    },
  },
};
