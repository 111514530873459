
import { Component, Mixins, Emit } from 'vue-property-decorator';
import store from '@/store';
import ButtonToday from '@/mixins/ButtonToday';
// @ is an alias to /src
@Component
export default class ButtonTodayLowestPrice extends Mixins(ButtonToday) {
  @Emit()
  private clickButton(): void {
    window.gtag('event', 'CrntFunction', {event_category: 'todayHotel', event_label: 'todayHotel01'});
  }
}
