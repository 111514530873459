import { Component, Vue, Emit } from 'vue-property-decorator';
import VueRouter from 'vue-router';
// @ is an alias to /src
import store from '@/store';
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeRouteLeave',
]);

@Component
export default class ViewPage extends Vue {
  protected beforeRouteLeave(to: any, from: VueRouter, next: any) {
    this.$store.commit('loaded', false);
  //   setTimeout(() => {
    next();
  //   }, 400);
  }
}
