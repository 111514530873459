import Vue from 'vue';
import Vuex from 'vuex';
import {Auth} from './auth';
import {Head} from './head';
import {Favorites} from './favorites';
import {History} from './history';
import {Search} from './search';
import {Searchdate} from './searchdate';
import {Pages} from './pages';
import {Single} from './single';
import {Today} from './today';
import {Movie} from './movie';
import {Breadcrumb} from './Breadcrumb';
import {Searchmodal} from './searchmodal';

Vue.use(Vuex);


export default new Vuex.Store({
  state: {
    loaded: false,
  },
  getters: {
    loaded: (state: any) => {
      return state.loaded;
    },
  },
  mutations: {
    loaded: (state: any, loaded: boolean) => {
      state.loaded = loaded;
    },
  },
  actions: {
  },
  modules: {
    head: Head,
    auth: Auth,
    favorites: Favorites,
    history: History,
    search: Search,
    pages: Pages,
    single: Single,
    searchdate: Searchdate,
    today: Today,
    movie: Movie,
    breadcrumb: Breadcrumb,
    searchmodal: Searchmodal,
  },
});
