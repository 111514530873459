import { Component, Watch, Emit, Prop, Vue } from 'vue-property-decorator';
import ButtonFavoriteHeart from '@/components/button/ButtonFavoriteHeart.vue';
import ButtonMovie from '@/components/button/ButtonMovie.vue';
import store from '@/store';
import HistoryDirective from '@/directives/history';
import GaSendSite from '@/directives/GaSendSite';
import UtilMixin from '@/mixins/util';
import $ from 'jquery';
// @ is an alias to /src

@Component({
  components: {
    ButtonFavoriteHeart,
    ButtonMovie,
  },
  directives: {
    history: HistoryDirective,
    gasendsite: GaSendSite,
  },
})
export default class BoxHotel extends Vue.extend(UtilMixin) {
  private isOpenAccess = false;
  get lang(): string {
    return this.$store.getters['auth/lang'];
  }
  @Prop()
  private datas!: HotelDetail;

  get mapLink(): string {
    let result!: string;
    if (this.datas) {
      if (this.testMapLink(this.datas.map)) {
        let path = '/' + this.lang;
        if (this.lang === 'ja') {
          path = '';
        }
        result = `${path}/hotel/name/${this.datas.slug}/map`;
      } else {
        result = this.datas.map;
      }
    }
    return result;
  }
  // @Emit()
  // private testMapLink(url: string): boolean {
  //   const regex = /^https:\/\/www\.google\.com\/maps\/embed/;
  //   return regex.test(url);
  // }
  get price(): string|undefined {
    return this.datas.price;
  }

  @Watch('isOpenAccess')
  private onChangeOpenAccess(newFlg: boolean, oldFlg: boolean) {
    const refs: any = this.$refs;
    if (newFlg) {
      $(refs.detail).stop().slideDown();
    } else {
      $(refs.detail).stop().slideUp();
    }
  }
  @Emit()
  private toggleDetail(): void {
    if (this.isOpenAccess) {
      this.isOpenAccess = false;
    } else {
      this.isOpenAccess = true;
    }
  }
}
