
import { Component, Vue, Emit, Watch, Prop } from 'vue-property-decorator';
import store from '@/store';
import $ from 'jquery';
import magnificPopup from 'magnific-popup';

@Component
export default class ModalMovie extends Vue {
  private name = 'ModalMovie';
  @Watch('$store.state.movie', { immediate: true, deep: true })
  private onChangeMovieStatus() {
    const src: string = this.$store.getters['movie/src'];
    if (src.length !== 0) {
      this.openMovieModal(src);
    }
  }
  @Emit()
  private openMovieModal(src: string): void {
    let iframeSrc = src.replace(/^https?:/g, '');
    // iframeSrc = 'https://31corp.sophia-s.co.jp/api/wp-content/uploads/2021/06/Pexels-Videos-1510138.mp4';
    const $modalMovie: any = $('#modalMovie');
    if (typeof $modalMovie !== 'undefined') {
      let addOption;
      const baseOption = {
        mainClass: 'modal-movie',
        callbacks: {
          close() {
            store.commit('movie/close');
          },
        },
      };
      if (/(https?:)?\/\/vimeo\.com\/.+\/.+/.test(iframeSrc)) {
        const id: any = iframeSrc.match(/\/\/vimeo\.com\/(.+)\/.+/);
        if (id[1]) {
          iframeSrc = `//vimeo.com/${id[1]}`;
        }
      }
      const iframeOption = {
          type: 'iframe',
          items: {
            src: iframeSrc,
          },
          removalDelay: 150,
          preloader: false,
          iframe: {
            markup: `
              <div class="mfp-iframe-scaler">
                <div class="mfp-close"></div>
                <iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'
              </div>
            `,
            patterns: {
              youtube: {
                index: 'youtube.com',
                id: 'v=',
                src: '//www.youtube.com/embed/%id%?autoplay=1&modestbranding=1',
              },
              vimeo: {
                index: 'vimeo.com/',
                id: '/',
                src: '//player.vimeo.com/video/%id%?autoplay=1',
              },
              srcAction: 'iframe_src',
            },
          },
      };
      const inlineOption = {
        items: {
            src: `
              <div class="mfp-iframe-scaler">
                <video controls class="mfp-iframe">
                  <source src="${iframeSrc}#t=1" type="video/mp4">
                </video>
              </div>
            `,
            type: 'inline',
        },
      };
      if (iframeSrc.indexOf('youtube.com') >= 0 || iframeSrc.indexOf('vimeo.com') >= 0) {
        addOption = iframeOption;
      } else {
        addOption = inlineOption;
      }
      const margeOption = Object.assign(baseOption, addOption);
      $.magnificPopup.open(margeOption);
    }
  }
}
