
import { Component, Watch, Vue } from 'vue-property-decorator';
// @ is an alias to /src

@Component
export default class CommonFooter extends Vue {
  private name = 'commonFooter';
  get lang(): string {
    return this.$store.getters['auth/lang'];
  }
  get isLogin(): boolean {
    return !!this.$store.getters['auth/token'];
  }
  get loaded(): boolean {
    return this.$store.state.loaded;
  }
}
