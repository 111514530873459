
import { Component, Vue, Emit, Watch } from 'vue-property-decorator';
import ModalTodayLowestPrice from '@/components/modal/ModalTodayLowestPrice/ModalTodayLowestPrice.vue';
import ModalSearchnavi from '@/components/modal/ModalSearchnavi/ModalSearchnavi.vue';
import ModalSearchDate from '@/components/modal/ModalSearchDate/ModalSearchDate.vue';
import store from '@/store';
import $ from 'jquery';
import magnificPopup from 'magnific-popup';
const magnificpopup = magnificPopup;
@Component({
  components: {
    ModalTodayLowestPrice,
    ModalSearchnavi,
    ModalSearchDate,
  },
})
export default class ModalSearch extends Vue {
  private name = 'ModalSearch';
  get isOpen(): boolean {
    return store.getters['searchmodal/open'];
  }
  get currentComponent(): string {
    return store.getters['searchmodal/component'];
  }
  @Watch('isOpen')
  private onModalStateChanged(newState: boolean) {
    if (newState) {
      this.open();
    } else {
      this.close();
    }
  }
  @Emit()
  private close() {
    $.magnificPopup.close();
  }
  @Emit()
  private open() {
    const $modalSearch: any = $('#modal_search');
    if (typeof $modalSearch !== 'undefined') {
      $.magnificPopup.open({
        items: {
          src: '#modal_search',
          type: 'inline',
        },
        callbacks: {
          close() {
            store.dispatch('searchmodal/close');
          },
        },
      });
    }
  }
  private mounted() {
    const $query: any = this.$route.query;
    if (typeof $query.today_calender_area !== 'undefined') {
      store.commit('searchmodal/open', 'ModalTodayLowestPrice');
    }
    if (typeof $query.hotel_calender_area !== 'undefined') {
      store.commit('searchmodal/open', 'ModalSearchDate');
    }
  }
}
