import store from '@/store';
export const endOfNumberPath = (path: string): boolean => {
  const regex = /\/[0-9]+$/;
  return regex.test(path);
};

export const validatePathIsEndOfNumber = (to: string) => {
  if (!endOfNumberPath(to)) {
    store.commit('search/setError', 2);
  } else {
    store.commit('search/setError', 0);
  }
};
