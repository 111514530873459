import axios, {AxiosResponse, AxiosError} from 'axios';
import store from '.';


export const Breadcrumb = {
  namespaced: true,
  state: {
    path: [],
  },
  getters: {
    path: (state: any) => {
      return state.path;
    },
  },
  mutations: {
    setPath: (state: any, path: BreadcrumbPath[]) => {
      state.path = path;
    },
  },
};
