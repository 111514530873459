
import { Component, Vue } from 'vue-property-decorator';
// @ is an alias to /src
import store from '@/store';
import SiteLogo from '@/components/SiteLogo.vue';
import ButtonLang from '@/components/button/ButtonLang.vue';

@Component({
  components: {
    SiteLogo,
    ButtonLang,
  },
})
export default class CommonHeaderJa extends Vue {
  private name = 'CommonHeaderJa';
  get isShow(): boolean {
    return this.$store.getters.loaded;
  }
}
