
import { Component, Vue, Emit, Watch, Prop } from 'vue-property-decorator';
import store from '@/store';
const WordsJa: Dictionary = {
  prev: '前週',
  next: '翌週',
  checkin: 'チェックイン日',
  legend: '：空室あり　×：満室',
  notice: ``,
  note: `※当該条件でご予約いただけるホテル・プランのみ表示しています。<br>
        ※カレンダーの表示は、お客様の検索時点での空室状況並びに最安値価格となります。<br>
        ※予約操作中に満室となった場合、ご予約を承れない可能性がございます。<br>
        ※検索時点でのご予約状況により、最安値価格が変動する可能性がございます。<br>
        ※表示価格は税込み表示となります。<br>
        ※表示価格は選択条件における最安値の総額価格を表示しています。`,
  btClose: '閉じる',
  btReserve: 'ご予約はこちらから',
};
const WordsEn: Dictionary = {
  prev: 'Previous',
  next: 'Next',
  checkin: '(Check-in dates)',
  legend: ':Available　×:Not available',
  note: `※Please note that above status is for reference only; therefore, the room may not be available at the time of your reservation.<br>
        ※In case of 2 nights or more, you can see ○ on the hotels where you can stay for 2 nights or more.<br>
        ※Check-in dates are based upon Japan Time(JST).`,
  btClose: 'CLOSE',
  btReserve: 'BOOK NOW',
};
@Component
export default class ModalSearchDate extends Vue {
  private name = 'modalsearchdate';
  private nowD: Date = new Date();
  private loaded: boolean = false;
  get lang(): string {
    return store.getters['auth/lang'];
  }
  get group(): string {
    return this.$store.getters['auth/group'];
  }
  get words() {
    if (this.lang === 'ja') {
      return WordsJa;
    } else {
      return WordsEn;
    }
  }
  get wordsDay(): string[] {
    if (this.lang === 'ja') {
      return ['日', '月', '火', '水', '木', '金', '土'];
    } else {
      return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    }
  }
  get wordsNote() {
    return this.words.note;
  }
  get reserveD(): Date {
    return this.$store.getters['searchdate/reserveD'];
  }
  set reserveD(value: Date) {
    this.$store.commit('searchdate/setReserveD', value);
  }
  get maxDate(): Date {
    return this.$store.getters['searchdate/maxDate'];
  }
  get reserveList(): ReservableList[] {
    return this.$store.getters['searchdate/reserveList'];
  }
  get valueStay(): number {
    return this.$store.getters['searchdate/valueStay'];
  }
  set valueStay(value: number) {
    this.$store.commit('searchdate/setValueStay', value);
  }
  get valuePeople(): number {
    return this.$store.getters['searchdate/valuePeople'];
  }
  get reserveListHeading(): string {
    let word = `Show the check-in dates when ${this.valuePeople + 1} people can stay for ${this.valueStay + 1} days`;
    if (this.lang === 'ja') {
      word = `${this.valuePeople + 1}名様が${this.valueStay + 1}泊でご宿泊可能な「チェックイン日」を表示`;
    }
    return word;
  }
  get inActiveCalPrev(): boolean {
    if (this.reserveD <= this.nowD) {
      return false;
    } else {
      return true;
    }
  }
  get inActiveCalNext(): boolean {
    if (new Date(this.reserveD.getFullYear(), this.reserveD.getMonth(), this.reserveD.getDate() + 7) < this.maxDate) {
      return true;
    } else {
      return false;
    }
    return true;
  }
  @Emit()
  private dayClass(n: number) {
    const DayClasses = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    const resD = new Date(this.reserveD.getFullYear(), this.reserveD.getMonth(), this.reserveD.getDate() + n);
    return DayClasses[resD.getDay()];
  }
  @Emit()
  private closeReserveList() {
    store.dispatch('searchmodal/close');
  }
  @Emit()
  private reserveListDate(n: number): string {
    const zero = (num: number): string => {
      const ret = ( '00' + num ).slice( -2 );
      return `${ret}`;
    };
    const resD = new Date(this.reserveD.getFullYear(), this.reserveD.getMonth(), this.reserveD.getDate() + n);
    return `${zero(resD.getMonth() + 1)}/${zero(resD.getDate())}`;
  }
  @Emit()
  private reserveListDay(n: number): string {
    const dayWeekStr: string[] = this.wordsDay;
    const resD = new Date(this.reserveD.getFullYear(), this.reserveD.getMonth(), this.reserveD.getDate() + n);
    return dayWeekStr[resD.getDay()];
  }
  @Emit()
  private async setPrevWeek() {
    const result: any = await store.dispatch(
      'searchdate/getReserveList',
      {
        startDate: new Date(this.reserveD.setDate(this.reserveD.getDate() - 7)),
      },
    );
  }
  @Emit()
  private async setNextWeek() {
    const result: any = await store.dispatch(
      'searchdate/getReserveList',
      {startDate: new Date(this.reserveD.setDate(this.reserveD.getDate() + 7))},
    );
  }
  @Emit()
  private onClickReseveBox(to: string): void {
    if (to) {
      window.open(to);
    }
  }
  @Emit()
  private async init(): void {
    const $query: any = this.$route.query;
    if (typeof $query === 'undefined' || typeof $query.hotel_calender_area === 'undefined') {
      this.loaded = true;
      return;
    }
    this.loaded = false;

    let people: number = 1;
    if (typeof $query.hotel_calender_people !== 'undefined') {
      people = Number($query.hotel_calender_people) - 1;
    }
    this.$store.commit('searchdate/setValuePeople', people);

    let stay: number = 1;
    if (typeof $query.hotel_calender_stay !== 'undefined') {
      stay = Number($query.hotel_calender_stay) - 1;
    }
    this.$store.commit('searchdate/setValueStay', stay);

    let date: Date = new Date();
    if (typeof $query.hotel_calender_date !== 'undefined') {
      const dateArr: string[] = $query.hotel_calender_date.split('/')
      if (dateArr.length === 3) {
        date = new Date(Number(dateArr[0]), Number(dateArr[1]) - 1, Number(dateArr[2]));
      }
    }
    
    const result: any = await store.dispatch('searchdate/getReserveList', {
      startDate: date,
      area: $query.hotel_calender_area,
    });
    this.loaded = true;
    // const tmp = magnificPopup;
    // $.magnificPopup.open({
    //   items: {
    //     src: '#reserveList',
    //     type: 'inline',
    //   },
    // });
    return;
  }
  private async mounted() {
    this.init();
  }
}
