import axios, {AxiosResponse, AxiosError} from 'axios';
export const Movie = {
  namespaced: true,
  state: {
    open: false,
    src: '',
  },
  getters: {
    src: (state: any) => {
      return state.src;
    },
    lang: (state: any, getters: any, rootState: any, rootGetters: any) => {
      return rootGetters['auth/lang'];
    },
  },
  mutations: {
    open: (state: any, src: string) => {
      if (src.indexOf('youtube.com') >= 0 || src.indexOf('vimeo.com') >= 0 || /\.mp4$/.test(src)) {
        state.src = src;
      }
    },
    close: (state: any) => {
      state.src = '';
    },
  },
};
