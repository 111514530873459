
import { Component, Mixins } from 'vue-property-decorator';
import HomeHistory from '@/components/home/HomeHistory';
import HomeHistoryItem from './HomeHistoryItem/HomeHistoryItem.vue';
const Words: Dictionary = {
  title01: `<span class="en">HISTORY</span><span class="ja">最近見たホテル</span>`,
  link: {
    open: '全て表示する',
    delete: '全件削除',
  },
  close: '閉じる',
  btMoreSp: '最近見たホテルを全て表示する',
};
@Component({
  components: {
    HomeHistoryItem,
  },
})
export default class HomeHistoryJa extends Mixins(HomeHistory) {
  private words = Words;
}
