
import { Component, Watch, Emit, Prop, Vue } from 'vue-property-decorator';
// @ is an alias to /src
import axios, {AxiosResponse, AxiosError} from 'axios';
import BoxPickup from '@/components/box/BoxPickup/BoxPickup.vue';
import $ from 'jquery';

@Component({
  components: {
    BoxPickup,
  },
})
export default class HomePickuplist extends Vue {
  private name = 'homepickuplist';
  private firstItemsLength: number = 4;
  private length: number|null = 4;
  private offset: number = 0;
  private list: PostData[] = [];
  private totaleItems: number = 0;
  private isOpend = false;
  get primaryItem(): PostData {
    return this.list[0];
  }
  get secondaryItem(): PostData[] {
    const result = this.list.filter((element, index, array) => {
      if (index !== 0 && index < this.firstItemsLength) {
        return element;
      }
    });
    return result;
  }
  get moreItem(): PostData[] {
    const result = this.list.filter((element, index, array) => {
      if (index >= this.firstItemsLength) {
        return element;
      }
    });
    return result;
  }
  get showMore(): boolean {
    if (this.totaleItems > this.firstItemsLength) {
      return true;
    } else {
      return false;
    }
  }
  @Emit()
  private async showMoreItems() {
    if (this.length && this.length > 0) {
      this.length = null;
      await this.getEntries();
    }
    if (this.isOpend) {
      this.isOpend = false;
    } else {
      this.isOpend = true;
    }
    this.$nextTick(() => {
      const $moreItems: any = $(this.$refs.moreItems);
      if (this.isOpend) {
        $moreItems.stop().slideDown();
      } else {
        $moreItems.stop().slideUp();
      }
    });
  }
  @Emit()
  private setList(list: PostData[]) {
    this.list = list;
  }
  @Emit()
  private async getEntries() {
    let api = `/api/wp-json/31corp/v1/posts?cat=pickup&offset=${this.offset}`;
    if (this.length) {
      api += `&length=${this.length}`;
    }
    await axios({
      method: 'get',
      url: api,
      headers: { Authorization: 'Bearer ' + this.$store.getters['auth/token']},
    })
    .then( (response: AxiosResponse<ApiPost>) => {
      this.totaleItems = Number(response.data.hits);
      this.setList(response.data.datas);
    })
    .catch( (e: AxiosError) => {
      console.log(e);
    });
  }
}
