
import { Component, Watch, Emit, Vue } from 'vue-property-decorator';
import store from '@/store';
// @ is an alias to /src


@Component
export default class ButtonLang extends Vue {
  private　name = 'buttonlang';
  private langText = 'English';
  get linkUrl(): string {
    let result: string = '';
    if (this.lang === 'ja') {
      result = '/en' + this.$route.fullPath;
    } else {
      result = this.$route.fullPath.replace(/^\/en(\/)?/, '/');
    }
    // return { path: '/en' + this.$route.path };
    return result;
  }
  get lang(): string {
    return this.$store.getters['auth/lang'];
  }
  @Watch('$store.state.auth.lang', { immediate: true, deep: true })
  private onChangeLangState() {
    if (this.lang === 'ja') {
      this.langText = 'English';
    } else {
      this.langText = 'Japanese';
    }
  }
}
