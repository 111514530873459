
import { Component, Mixins } from 'vue-property-decorator';
import NaviFollowSearchnavi from '@/components/navi/NaviFollowSearchnavi';
import NaviSerchnavi from '@/components/navi/NaviSerchnavi/NaviSerchnaviEn.vue';
const Words: Dictionary = {
  btSearchBox: 'Search for hotels',
  btClose: 'CLOSE',
};
@Component({
  components: {
    NaviSerchnavi,
  },
})
export default class NaviFollowSearchnaviJa extends Mixins(NaviFollowSearchnavi) {
  private words = Words;
  private showLinebnr = false;
}
