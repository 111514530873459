import axios, {AxiosResponse, AxiosError} from 'axios';
export const Today = {
  namespaced: true,
  state: {
    loaded: false,
    open: false,
    area: 'tokyo',
    stay: 1,
    people: 1,
    hotels: [],
    options: {
      'sapporo': {
        text: {
          ja: '札幌',
          en: 'Sapporo',
        },
      },
      'sendai': {
        text: {
          ja: '仙台',
          en: 'Sendai',
        },
      },
      'tokyo': {
        text: {
          ja: '東京',
          en: 'Tokyo',
        },
      },
      'yokohama': {
        text: {
          ja: '横浜',
          en: 'Yokohama',
        },
      },
      'chiba': {
        text: {
          ja: '千葉',
          en: 'Chiba',
        },
      },
      'nagoya': {
        text: {
          ja: '名古屋',
          en: 'Nagoya',
        },
      },
      // 'shizuoka': {
      //   text: {
      //     ja: '静岡',
      //     en: 'Shizuoka',
      //   },
      // },
      'kanazawa': {
        text: {
          ja: '金沢',
          en: 'Kanazawa',
        },
      },
      'kyoto': {
        text: {
          ja: '京都',
          en: 'Kyoto',
        },
      },
      'osaka': {
        text: {
          ja: '大阪',
          en: 'Osaka',
        },
      },
      'hiroshima-okayama': {
        text: {
          ja: '広島・岡山',
          en: 'Hiroshima/Okayama',
        },
      },
      'fukuoka-kumamoto': {
        text: {
          ja: '福岡・熊本',
          en: 'Fukuoka/Kumamoto',
        },
      },
    },
  },
  getters: {
    loaded: (state: any) => {
      return state.loaded;
    },
    open: (state: any) => {
      return state.open;
    },
    area: (state: any) => {
      return state.area;
    },
    stay: (state: any) => {
      return state.stay;
    },
    people: (state: any) => {
      return state.people;
    },
    hotels: (state: any) => {
      return state.hotels;
    },
    options: (state: any) => {
      return state.options;
    },
    token: (state: any, getters: any, rootState: any, rootGetters: any) => {
      return rootGetters['auth/token'];
    },
    lang: (state: any, getters: any, rootState: any, rootGetters: any) => {
      return rootGetters['auth/lang'];
    },
  },
  mutations: {
    area: (state: any, area: string) => {
      state.area = area;
    },
    stay: (state: any, stay: number) => {
      state.stay = stay;
    },
    people: (state: any, people: number) => {
      state.people = people;
    },
    hotels: (state: any, hotels: ReservableList[]) => {
      state.hotels = hotels;
    },
    open: (state: any, area: string = 'tokyo') => {
      state.area = area;
      state.open = true;
    },
    close: (state: any) => {
      state.open = false;
    },
    loaded: (state: any, bool: boolean) => {
      state.loaded = bool;
    },
  },
  actions: {
    getReserveList: async (cxt: any, params: any) => {
      const zero = (num: number): string => {
        const ret = ( '00' + num ).slice( -2 );
        return `${ret}`;
      };
      const result = await axios({
        method: 'get',
        url: `/api/wp-json/31corp/v1/reservable?vacancy_date=${params.startDate.getFullYear()}/${zero(params.startDate.getMonth() + 1)}/${zero(params.startDate.getDate())}&vacancy_days=${params.stay}&man_count=${params.people}&area=${params.area}&get_days=1&lang=${cxt.getters.lang}`,
        headers: { Authorization: 'Bearer ' + cxt.getters.token},
      })
      .then( (response: AxiosResponse<ApiReservable>) => {
        const reserveList = response.data.hotels.map((value: any) => {
          const nullObj = {
            date: '',
            day: '',
            reservable: false,
            price: '',
            url: '',
          };
          const nullArr = [nullObj, nullObj, nullObj, nullObj, nullObj, nullObj, nullObj];
          if (typeof value.schedule !== "undefined" && value.schedule.length > 0) {
            return value;
          } else {
            return {heading: value.detail.name, schedule: nullArr, detail: value.detail };
          }
        });
        cxt.commit('hotels', response.data.hotels);
      })
      .catch( (e: AxiosError) => {
        console.log(e);
        cxt.commit('hotels', []);
      });
    },
  },
};
