import { Component, Vue, Emit } from 'vue-property-decorator';
import store from '@/store';
@Component
export default class ButtonToday extends Vue {
  @Emit()
  private openTodayModal(area: string = 'tokyo'): void {
    store.commit('today/area', area);
    store.commit('searchmodal/open', 'ModalTodayLowestPrice');
  }
  @Emit()
  private setTodayArea(value: string) {
    store.commit('today/area', value);
  }
}
